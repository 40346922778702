import React, { useState, useEffect } from 'react';

import { Badge } from 'reactstrap';

export function renderRoleTd(value: string | undefined) {
  switch (value) {
    case 'SUPER_ADMIN':
      return (
        <th>
          <Badge color="danger">Super Admin</Badge>
        </th>
      );
    case 'ADMIN':
      return (
        <th>
          <Badge color="primary">Admin</Badge>
        </th>
      );
    case 'EDITOR':
      return (
        <th>
          <Badge color="success">Editor</Badge>
        </th>
      );
    case 'VIEWERS':
      return (
        <th>
          <Badge color="warning">Viewer</Badge>
        </th>
      );
  }
}
