export const mouStages = {
  STAGE_1: 'Start',
  STAGE_2: 'Pending at Legal Division',
  STAGE_3: 'Pending at Faculty Board',
  STAGE_4: 'Pending at ADPC',
  STAGE_5: 'Pending at Faculty',
  STAGE_6: 'Pending at Senate',
  STAGE_7: 'Pending at Council',
  STAGE_8: 'Pending at UGC',
  STAGE_9: 'Pending at MOE',
  STAGE_10: 'Pending at NPD',
  STAGE_11: 'Pending at ERD',
  STAGE_12: 'Pending at MOFA',
  STAGE_13: 'Pending at AG',
  STAGE_14: 'Pending at Cabinet',
  STAGE_15: 'OnGoing',
};
