import { schema } from 'normalizr';

export interface ItemList<T> {
  items: { [key: string]: T };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getSchema = (idAttribute: string) => {
  const items = new schema.Entity('items', {}, { idAttribute });
  return [items];
};

export interface NormalizedSchema<T> {
  items: { entities: ItemList<T>; result: Array<string> } | null;
}
