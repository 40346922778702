import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AppTable } from '../../../shared/components';

interface ParamsProps {
  facultyId: string;
}
const FacultyViewPage: React.FC = () => {
  const getFacultyViewPageViewTableData = () => {
    const array: Array<JSX.Element> = [];
    return array;
  };

  const { facultyId } = useParams<ParamsProps>();

  return (
    <>
      <div className="container pt-3 main-container-div">
        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="table_outer_div">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 header-txt">
                    <h5>MOUs Related to the Faculty</h5>
                  </div>
                </div>
              </div>

              <AppTable
                head={[
                  '', //stage
                  'Name',
                  'Partner Institute',
                  '', //country
                  'Expiring Date',
                  '', //mou details
                ]}
                data={getFacultyViewPageViewTableData()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FacultyViewPage;
