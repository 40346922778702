import CountryPage from '../pages/CountryListPage';

export const COUNTRIES = '/countries';

export default [
  {
    path: COUNTRIES,
    component: CountryPage,
    authenticated: true,
    permissions: [],
    exact: true,
  },
];
