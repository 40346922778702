import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../../../app/redux/rootReducer';
import { UserRole } from '../../../../models/userRole';
import { WorkInProgress } from '../../../../shared/components';
import { getUserRole } from '../../../auth/redux/authSelector';
import { roleTypes } from '../../../../constants/userRole';

import AdminDashboard from '../../component/AdminDashboard';
import ViewerDashboard from '../../component/ViewerDashboard';
import EditorDashboard from '../../component/EditorDashboard';

import './Dashboard.css';

interface Props {
  userRole: UserRole | null;
}

const Dashboard: React.FC<Props> = ({ userRole }: Props) => {
  useEffect(() => {
    document.title = 'Home';
  }, []);

  const getUserDashboard = () => {
    if (userRole) {
      switch (userRole.roleName) {
        case roleTypes.SUPER_ADMIN:
          return <WorkInProgress />;
        case roleTypes.VIEWERS:
          return <ViewerDashboard />;
        case roleTypes.ADMIN:
          return <AdminDashboard />;
        case roleTypes.EDITOR:
          return <EditorDashboard />;
        default:
          return <WorkInProgress />;
      }
    } else {
      return <WorkInProgress />;
    }
  };

  return <div>{getUserDashboard()}</div>;
};
//  <div>{getUserDashboard()}</div>
const mapStateToProps = (state: RootState) => ({
  userRole: getUserRole(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
