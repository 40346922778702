import React, { useEffect, useState } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

import './AppPagination.css';

interface Props {
  dataLength: number;
  limit?: number;
  callBack?: (pages: number, from: number, to: number) => void;
  innerRange?: number;
  currentPage?: number;
}

const AppPagination = ({
  dataLength,
  limit = 10,
  callBack,
  innerRange = 10,
  currentPage = 1,
}: Props) => {
  const [current, setCurrent] = useState(currentPage);
  const pages =
    dataLength % limit === 0 ? dataLength / limit : ~~(dataLength / limit) + 1;

  const callBackHandler = (currentPage: number) => {
    const from = (currentPage - 1) * limit + 1;
    const to = currentPage === pages ? dataLength : from + limit - 1;
    callBack && callBack(currentPage, from, to);
  };

  const firstPaginationHandler = () => {
    setCurrent(1);
    callBackHandler(1);
  };

  const previousPaginationHandler = () => {
    if (current > 1) {
      setCurrent(current - 1);
      callBackHandler(current - 1);
    }
  };

  const paginationHandler = (page: number) => {
    setCurrent(page);
    callBackHandler(page);
  };

  const nextPaginationHandler = () => {
    if (current < pages) {
      setCurrent(current + 1);
      callBackHandler(current + 1);
    }
  };
  const lastPaginationHandler = () => {
    setCurrent(pages);
    callBackHandler(pages);
  };

  const pushPagination = (
    array: Array<JSX.Element>,
    from: number,
    to: number
  ) => {
    for (let index = from; index <= to; index++) {
      array.push(
        <PaginationItem key={String(index)} active={index === current}>
          <PaginationLink onClick={() => paginationHandler(index)}>
            {index}
          </PaginationLink>
        </PaginationItem>
      );
    }
  };

  const pushEndPagination = (array: Array<JSX.Element>) => {
    array.push(
      <PaginationItem key=".." disabled>
        <PaginationLink>..</PaginationLink>
      </PaginationItem>
    );
    array.push(
      <PaginationItem key={String(pages)} active={pages === current}>
        <PaginationLink onClick={() => paginationHandler(pages)}>
          {pages}
        </PaginationLink>
      </PaginationItem>
    );
  };

  const getPagination = () => {
    const array = [];
    array.push(
      <PaginationItem key="first" disabled={current === 1}>
        <PaginationLink first onClick={firstPaginationHandler} />
      </PaginationItem>
    );
    array.push(
      <PaginationItem key="previous" disabled={current === 1}>
        <PaginationLink previous onClick={previousPaginationHandler} />
      </PaginationItem>
    );

    if (pages > innerRange) {
      if (current >= innerRange) {
        if (current === pages || current === pages - 1) {
          pushPagination(array, pages - innerRange, pages);
        } else {
          pushPagination(array, current - innerRange + 1, current + 1);
          pushEndPagination(array);
        }
      } else {
        pushPagination(array, 1, innerRange);
        pushEndPagination(array);
      }
    } else {
      pushPagination(array, 1, pages);
    }

    array.push(
      <PaginationItem key="next" disabled={current === pages}>
        <PaginationLink next onClick={nextPaginationHandler} />
      </PaginationItem>
    );
    array.push(
      <PaginationItem key="last" disabled={current === pages}>
        <PaginationLink last onClick={lastPaginationHandler} />
      </PaginationItem>
    );
    return array;
  };

  return (
    <Pagination
      className="table-pagination"
      aria-label="Page navigation example"
    >
      {getPagination()}
    </Pagination>
  );
};

export default AppPagination;
