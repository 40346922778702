import { Observable } from 'rxjs';

import { GET, POST, PUT } from './baseApi';
import { UserRole } from '../../models/userRole';

const USER_ROLE_ENDPOINT = '/role/';

export const getUserRoles = (): Observable<Array<UserRole>> =>
  GET(USER_ROLE_ENDPOINT);

export const getUserRole = (roleId: string): Observable<UserRole> =>
  GET(`${USER_ROLE_ENDPOINT}${roleId}`);

export const createUserRole = (userRole: UserRole): Observable<UserRole> =>
  POST(USER_ROLE_ENDPOINT, { ...userRole });

export const updateUserRole = (userRole: UserRole): Observable<UserRole> =>
  PUT(`${USER_ROLE_ENDPOINT}${userRole.roleId}`, { ...userRole });
