import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import { RootState } from '../../../app/redux/rootReducer';
import { Mou } from '../../../models/mou';
import { getSchema, ItemList } from '../../../app/redux/schema';
import { ApplicationType } from '../../../models/applicationType';
import { Coordinator } from '../../../models/coordinator';
import { Country } from '../../../models/country';
import { Faculty } from '../../../models/faculty';
import { Stage } from '../../../models/stage';
import { University } from '../../../models/university';

// application type
const applicationTypesSelector = (state: RootState) =>
  state.organization.applicationType;

const applicationTypeSelector = (state: RootState, key: string) => ({
  applicationTypes: state.organization.applicationType,
  key,
});

const getApplicationTypes = createSelector(
  applicationTypesSelector,
  (applicationTypes): Array<ApplicationType> => {
    if (applicationTypes) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const array: Array<ApplicationType> = denormalize(
        applicationTypes.result,
        getSchema('applicationTypeId'),
        applicationTypes.entities
      );
      return array;
    }
    return [];
  }
);

const getApplicationType = createSelector(
  applicationTypeSelector,
  ({ applicationTypes, key }) => {
    if (applicationTypes?.entities) {
      const list = applicationTypes?.entities as ItemList<ApplicationType>;
      return list.items[key];
    }
    return undefined;
  }
);

// coordinators
const coordinatorsSelector = (state: RootState) =>
  state.organization.coordinator;

const coordinatorSelector = (state: RootState, key: string) => ({
  coordinators: state.organization.coordinator,
  key,
});

const getCoordinators = createSelector(
  coordinatorsSelector,
  (coordinators): Array<Coordinator> => {
    if (coordinators) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const array: Array<Coordinator> = denormalize(
        coordinators.result,
        getSchema('coordinatorId'),
        coordinators.entities
      );
      return array;
    }
    return [];
  }
);

const getCoordinator = createSelector(
  coordinatorSelector,
  ({ coordinators, key }) => {
    if (coordinators?.entities) {
      const list = coordinators?.entities as ItemList<Coordinator>;
      return list.items[key];
    }
    return undefined;
  }
);

// countries
const countriesSelector = (state: RootState) => state.organization.country;

const countrySelector = (state: RootState, key: string) => ({
  countries: state.organization.country,
  key,
});

const getCountryList = createSelector(countriesSelector, ({ list }) => {
  if (list != null) {
    const array = denormalize(
      list.result,
      getSchema('countryCode'),
      list.entities
    ) as Array<Country>;
    return array;
  }
  return [] as Array<Country>;
});

const getCountry = createSelector(countrySelector, ({ countries, key }) => {
  if (countries.list?.entities) {
    const list = countries.list.entities;
    return list.items[key];
  }
  return undefined;
});

// faculties
const facultiesSelector = (state: RootState) => state.organization.faculty;

const facultySelector = (state: RootState, key: string) => ({
  faculties: state.organization.faculty,
  key,
});

const getFaculties = createSelector(
  facultiesSelector,
  (faculties): Array<Faculty> => {
    if (faculties) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const array: Array<Faculty> = denormalize(
        faculties.result,
        getSchema('facultyId'),
        faculties.entities
      );
      return array;
    }
    return [];
  }
);

const getFaculty = createSelector(facultySelector, ({ faculties, key }) => {
  if (faculties?.entities) {
    const list = faculties?.entities as ItemList<Faculty>;
    return list.items[key];
  }
  return undefined;
});

// stage
const stagesSelector = (state: RootState) => state.organization.stage;

const stageSelector = (state: RootState, key: string) => ({
  stages: state.organization.stage,
  key,
});

const getStageList = createSelector(stagesSelector, ({ list }) => {
  if (list != null) {
    const array = denormalize(
      list.result,
      getSchema('stageId'),
      list.entities
    ) as Array<Stage>;
    return array;
  }
  return [] as Array<Stage>;
});

const getStage = createSelector(stageSelector, ({ stages, key }) => {
  if (stages.list?.entities) {
    const list = stages.list.entities;
    return list.items[key];
  }
  return undefined;
});

// university
const universitiesSelector = (state: RootState) =>
  state.organization.university;

const universitySelector = (state: RootState, key: string) => ({
  universities: state.organization.university,
  key,
});

const getUniversities = createSelector(
  universitiesSelector,
  (universities): Array<University> => {
    if (universities) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const array: Array<University> = denormalize(
        universities.result,
        getSchema('universityCode'),
        universities.entities
      );
      return array;
    }
    return [];
  }
);

const getUniversity = createSelector(
  universitySelector,
  ({ universities, key }) => {
    if (universities?.entities) {
      const list = universities?.entities as ItemList<University>;
      return list.items[key];
    }
    return undefined;
  }
);

const mouSelector = (state: RootState) => state.organization.mou;

const getMouList = createSelector(
  mouSelector,
  ({ items }): Array<Mou> => {
    if (items) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const array = denormalize(
        items.result,
        getSchema('mouId'),
        items.entities
      );
      return array as Array<Mou>;
    }
    return [] as Array<Mou>;
  }
);

export {
  getApplicationTypes,
  getApplicationType,
  getCoordinators,
  getCoordinator,
  getCountryList,
  getCountry,
  getFaculties,
  getFaculty,
  getStageList,
  getStage,
  getUniversities,
  getUniversity,
  getMouList,
  // getMou,
};
