import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

import { AppButton, AppInput } from '../../../shared/forms/components';
import { CreateFacultyForm, Faculty } from '../../../models/faculty';
//import {NewAuth } from '../../../models/auth';
import { notificationActions } from '../../../app/redux/notification.slice';
import { useHistory } from 'react-router';
import { AxiosError } from 'axios';

import miscUtils from '../../../utils/miscUtils';
import { createFaculty } from '../../../services/api/facultyApi';

const getInitialValues = () => ({
  name: '',
  email: '',
  phoneNumber: '',
});

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(30, 'maximum of 30 letters')
    .required('This is a required field'),
  email: Yup.string()
    .email('Should be a valid Email')
    .required('This is a required field'),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('This is a required field'),
});

interface Props {
  createFacultySuccessCallBack?: () => void;
}

const AddFacultyForm: React.FC<Props> = ({
  createFacultySuccessCallBack,
}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandler = (values: CreateFacultyForm) => {
    setIsLoading(true);

    const facultyId = miscUtils.generateUuid();

    createFaculty({ ...values, facultyId }).subscribe(
      (response) => {
        dispatch(
          notificationActions.setToast({
            severity: 'success',
            message: 'Successfully created Faculty',
            title: 'Success',
          })
        );
        createFacultySuccessCallBack && createFacultySuccessCallBack();
      },
      (error: AxiosError) => {
        dispatch(
          notificationActions.setToast({
            severity: 'danger',
            message: error.message,
            title: 'Error: Failed To Create Faculty',
          })
        );
        setIsLoading(false);
      }
    );
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      <Form>
        <div className="d-flex justify-content-center text-center" />
        <AppInput
          name="name"
          label="Faculty Name"
          placeholder="Faculty Name"
          type="text"
        />
        <AppInput
          name="email"
          label="Faculty Email"
          placeholder="Faculty Email"
          type="email"
        />
        <AppInput
          name="phoneNumber"
          label="Faculty Phone Number"
          placeholder="Phone Number"
          type="text"
        />
        <AppButton type="submit" text="Add" />
      </Form>
    </Formik>
  );
};

export default AddFacultyForm;
