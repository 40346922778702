/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Avatar from 'react-avatar';
import { useHistory } from 'react-router';
import { Badge } from 'reactstrap';
import { BiCollapse, BiExpand } from 'react-icons/bi';
import { IoNotificationsOutline } from 'react-icons/io5';
import { IoMdList } from 'react-icons/io';
import { Link } from 'react-router-dom';

import { getName, getUserRole } from '../../../modules/auth/redux/authSelector';
import { authActions } from '../../../modules/auth/redux/authSlice';
import { RootState } from '../../redux/rootReducer';
import { PROFILE } from '../../../modules/dashboard/routes';
import { AppModal } from '../../../shared/components';
import UpdatePasswordForm from '../../../modules/user/forms/UpdatePasswordForm';

import { UserRole } from '../../../models/userRole';
import { roleTypes } from '../../../constants/userRole';

import './TopBar.css';

interface TopBaProps {
  name?: string;
  userRole: UserRole | null;
  logOut: () => void;
}

const TopBar: React.FC<TopBaProps> = ({ name, userRole, logOut }) => {
  const history = useHistory();
  const [showModalChangePassword, setShowModalChangePassword] = useState(false);
  const [isCollapsed, setCollapsed] = useState(true);
  const [notifications, setNotifications] = useState<Array<JSX.Element>>([]);

  const onSignOutHandler = () => {
    logOut();
  };

  const onProfileHandler = () => {
    history.push(PROFILE);
  };

  function handleModalChangePasswordOpen() {
    setShowModalChangePassword(!showModalChangePassword);
  }

  function handleModalChangePasswordClose() {
    setShowModalChangePassword(!showModalChangePassword);
  }
  function ClickCollapseBtn() {
    const vwG = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );

    const navBarDivElement = document.getElementById('side-bar-left')!;
    const sideBarItemList = Array.from(
      document.getElementsByClassName(
        'sidebar-item-text'
      ) as HTMLCollectionOf<HTMLElement>
    );

    if (isCollapsed) {
      navBarDivElement.style.width = '220px';
      for (let i = 0; i < sideBarItemList.length; i++) {
        sideBarItemList[i].style.visibility = 'visible';
        sideBarItemList[i].style.display = 'block';
      }
    } else {
      const vwG = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );

      if (vwG > 600) {
        navBarDivElement.style.width = '45px';
      } else {
        navBarDivElement.style.width = '0px';
      }
      for (let i = 0; i < sideBarItemList.length; i++) {
        sideBarItemList[i].style.visibility = 'hidden';
        sideBarItemList[i].style.display = 'none';
      }
    }

    setCollapsed(!isCollapsed);
  }

  useEffect(() => {
    const vwG = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );

    if (vwG > 960) {
      const navBarDivElement = document.getElementById('side-bar-left')!;

      setTimeout(function () {
        navBarDivElement.style.width = '220px';
        setCollapsed(!isCollapsed);
      }, 3000);
    }
  }, []);

  return (
    <div>
      <nav className="header-nav-bar">
        <div className="header-logo">
          <Link to="/">
            <img
              className="site-logo"
              src="/images/logo/University_of_Peradeniya_crest-1.png"
              alt="logo"
            />
          </Link>
        </div>
        <a className="collapse-btn-nav-bar" onClick={() => ClickCollapseBtn()}>
          {isCollapsed && <IoMdList />}
          {!isCollapsed && <IoMdList />}
        </a>

        {name && (
          <>
            {(userRole?.roleName === roleTypes.ADMIN ||
              userRole?.roleName === roleTypes.EDITOR) &&
              notifications.length > 0 && (
                <div className="dropdown-force-style">
                  <Dropdown className="topNav-dropdown topNav-dropdown-notification">
                    <Dropdown.Toggle className="notification-topbar">
                      <div className="notification-div">
                        <IoNotificationsOutline size={23} />
                        <Badge className="notification-number" color="danger">
                          {notifications.length}
                        </Badge>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>{notifications}</Dropdown.Menu>
                  </Dropdown>
                </div>
              )}

            <div className="profile_dropdown dropdown-force-style">
              <Dropdown className="topNav-dropdown">
                <Dropdown.Toggle>
                  <span className="set-name">Hi {name}</span>
                  <Avatar
                    name={name}
                    size="40"
                    round="50%"
                    color="white"
                    fgColor="#303f9f"
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={onProfileHandler}>
                    My Profile
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleModalChangePasswordOpen()}
                  >
                    Change Password
                  </Dropdown.Item>
                  <Dropdown.Item onClick={onSignOutHandler}>
                    Log Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </>
        )}
      </nav>
      <AppModal
        onClose={() => handleModalChangePasswordClose()}
        show={showModalChangePassword}
        title="Change Password"
      >
        <UpdatePasswordForm
          onSubmitClick={() => handleModalChangePasswordClose()}
        />
      </AppModal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  name: getName(state),
  userRole: getUserRole(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  logOut: () => dispatch(authActions.logoutUser()),
});
export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
