import { ErrorMessage, Field } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';

import './AppInput.css';

interface AppInputProps {
  name: string;
  type: string;
  placeholder: string;
  label?: string;
  readOnly?: boolean;
  loading?: boolean;
}

const Label = styled.label`
  font-size: 1rem;
`;
const Input = styled.input`
  padding: 5px 10px;
`;
const ErrorLabel = styled.span`
  fontsize: 0.8rem;
  color: red;
`;

const Container = styled.div`
  display: flex;
  margin: 10px 0px;
  flex-direction: column;
`;

const AppInput = (props: AppInputProps): JSX.Element => {
  const { type, placeholder, label, name, loading, readOnly } = props;
  const [showPassword, setShowPassword] = useState<boolean>(true);

  return (
    <Container className="mb-4 input-box-outer-div">
      {label && <p className="input-box-top-name">{label}</p>}
      <div className="form-outer-div">
        <Field
          as={Input}
          disabled={loading}
          name={name}
          placeholder={placeholder}
          type={showPassword ? type : 'text'}
          readOnly={readOnly}
          className="input-field"
        />
        {showPassword && type === 'password' && (
          <AiFillEyeInvisible
            className="eye-icon-password"
            size={20}
            onClick={(e) => setShowPassword(!showPassword)}
          />
        )}
        {!showPassword && type === 'password' && (
          <AiFillEye
            className="eye-icon-password"
            size={20}
            onClick={(e) => setShowPassword(!showPassword)}
          />
        )}
      </div>

      <ErrorMessage name={name}>
        {(error: string) => <ErrorLabel>{error}</ErrorLabel>}
      </ErrorMessage>
    </Container>
  );
};

AppInput.defaultProps = {
  label: '',
  loading: false,
  readOnly: false,
};

export default AppInput;
