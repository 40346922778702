import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { emptyMeta, emptyState } from '../../constants/api';
import { ListRequest, ListResponseState } from '../../models/api';

import { UserRole } from '../../models/userRole';
import { getSchema } from './schema';

import { User } from '../../models/user';

export interface AppReducerState {
  appLoading: boolean;
  userRoles: ReturnType<typeof normalize> | null;
  users: ReturnType<typeof normalize> | null;
}

export const initialState: AppReducerState = {
  appLoading: true,
  userRoles: null,
  users: null,
};

const handleLoadingSuccess = (state: AppReducerState): void => {
  // eslint-disable-next-line no-param-reassign
  state.appLoading = false;
};

const setLoading = (
  state: AppReducerState,
  action: PayloadAction<boolean>
): void => {
  // eslint-disable-next-line no-param-reassign
  state.appLoading = action.payload;
};

const fetchUserRoleListSuccess = (
  state: AppReducerState,
  action: PayloadAction<Array<UserRole>>
): void => {
  // eslint-disable-next-line no-param-reassign
  state.userRoles = normalize(action.payload, getSchema('roleId'));
};

const fetchUserListSuccess = (
  state: AppReducerState,
  action: PayloadAction<Array<User>>
): void => {
  state.users = normalize(action.payload, getSchema('userId'));
};

const rootSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    loadingSuccess: handleLoadingSuccess,
    setLoading,
    fetchUserRoleListSuccess,
    fetchUserListSuccess,
  },
});

const epicActions = {
  fetchUserRoleList: createAction('fetchUserRoles'),
  fetchUserList: createAction('fetchUsers'),
};

export const appAction = {
  ...rootSlice.actions,
  ...epicActions,
};

export default rootSlice.reducer;
