import AdminUserDetails from '../../dashboard/container/Admin/AdminUserDetails';
import UserViewPage from '../page/UserView';

export const USERS = '/users';
export const USER = `${USERS}/:id`;

export default [
  {
    path: USERS,
    component: AdminUserDetails,
    authenticated: true,
    permissions: [],
    exact: true,
  },
  {
    path: USER,
    component: UserViewPage,
    authenticated: true,
    permissions: [],
    exact: true,
  },
];
