import React from 'react';
import styled from 'styled-components';

import { Loader } from '..';

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
`;

const WorkInProgress: React.FC = (): JSX.Element => (
  <Container>
    <Loader />
  </Container>
);

export default WorkInProgress;
