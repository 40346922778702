import { Observable } from 'rxjs';

import { GET, POST, PUT } from './baseApi';
import { ApplicationType } from '../../models/applicationType';

const APPLICATION_TYPE_ENDPOINT = '/applicationType/';

export const getApplicationTypes = (): Observable<Array<ApplicationType>> =>
  GET(APPLICATION_TYPE_ENDPOINT);

export const getApplicationType = (
  applicationTypeId: string
): Observable<ApplicationType> =>
  GET(`${APPLICATION_TYPE_ENDPOINT}${applicationTypeId}`);

export const createApplicationType = (
  applicationType: ApplicationType
): Observable<ApplicationType> =>
  POST(APPLICATION_TYPE_ENDPOINT, { ...applicationType });

// export const updateApplicationType = (
//   applicationType: ApplicationType
// ): Observable<ApplicationType> =>
//   PUT(`${APPLICATION_TYPE_ENDPOINT}${applicationType.applicationTypeId}`, {
//     ...applicationType,
//   });
