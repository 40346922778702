import React, { useState, useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdViewHeadline } from 'react-icons/md';
import moment from 'moment-timezone';

import { RootState } from '../../../app/redux/rootReducer';
import { Mou, MousRequest } from '../../../models/mou';
import {
  getApplicationType,
  getFaculty,
  getMouList,
  getStage,
  getUniversity,
} from '../../dashboard/redux/organizationSelector';
import { organizationActions } from '../../dashboard/redux/organizationSlice';

import { AppPagination, AppTable, Loader } from '../../../shared/components';
import { DATE_FORMAT } from '../../../constants/dateTime';
import { ApplicationType } from '../../../models/applicationType';
import { Stage } from '../../../models/stage';
import { University } from '../../../models/university';
import { Faculty } from '../../../models/faculty';
import { Badge } from 'reactstrap';
import { mouStages } from '../../../constants/mouStages';
import SearchBox, { FilterValues } from '../components/SearchBox/SearchBox';
import { renderStage } from '../../../utils/stageGetFunction';

interface Props {
  getMouList: () => Array<Mou>;
  fetchMous: (param?: MousRequest) => void;
  getApplicationTypeDetails: (
    applicationTypeId: string
  ) => ApplicationType | undefined;
  getStageDetails: (stageId: string) => Stage | undefined;
  getUniversityDetails: (universityCode: string) => University | undefined;
  getFacultyDetails: (facultyCode: string) => Faculty | undefined;
}

const MouPage: React.FC<Props> = ({
  getMouList,
  fetchMous,
  getApplicationTypeDetails,
  getStageDetails,
  getUniversityDetails,
  getFacultyDetails,
}: Props) => {
  const [mouList, setMouList] = useState<Array<Mou>>([]);

  const state = useSelector((state: RootState) => state);
  const { loading, meta, items, filters } = state.organization.mou;

  useEffect(() => {
    document.title = 'Mous';

    if (items) {
      setMouList(getMouList());
    } else if (!loading) {
      fetchMous();
    }
  }, [items]);

  const handleCallBack = (pages: number, from: number, to: number) => {
    fetchMous({
      ...state.organization.mou.filters,
      from,
      to: to + 1,
    });
  };

  const getMouTableData = () => {
    const array: Array<JSX.Element> = [];
    mouList.map((item, i) => {
      let countryCode = getUniversityDetails(item.universityCode)?.countryCode;
      countryCode = countryCode?.concat('.svg');
      array.push(
        <tr key={item.mouId}>
          {renderStage(getStageDetails(item.stageId)?.stageName)}
          {/*<td>{item.mouName}</td>*/}
          <td>{getUniversityDetails(item.universityCode)?.universityName}</td>
          {/* <td>{getUniversityDetails(item.universityCode)?.countryCode}</td> */}
          <td>
            <img
              src={countryCode && '/images/country_flags/'.concat(countryCode)}
              alt={countryCode}
              className="country-image-mou"
            />
          </td>
          {<td>{getFacultyDetails(item.facultyId)?.name}</td>}
          <td>
            {moment(item.expiringDate)
              .tz(moment.tz.guess())
              .format(DATE_FORMAT)}
          </td>
          <td>
            <Link
              to={'mous/'.concat(item.mouId)}
              className="add-new-link-btn-thin-border ft-size-16"
            >
              <MdViewHeadline size={20} />
            </Link>
          </td>
        </tr>
      );
    });
    return array;
  };

  function handleFilterChange(value: FilterValues) {
    console.log(value);
    fetchMous({
      stageId: value.stageId,
      countryCode: value.countryCode,
    });
  }

  return (
    <div className="container main-container-div">
      <div className="row">
        <div className="col-lg-12">
          {/* <SearchBox
          filterValueObj={{
            machineId: filters?.machineId || '',
            machineTypeId: '',
            activityId: filters?.activityId || '',
            dateRange: dateRange,
            responsiblePersonId: filters?.userId || '',
          }}
          selectedFilter={(values) => handleFilterChange(values)}
          /> */}
          <SearchBox
            filterValueObj={
              filters
                ? {
                    stageId: filters?.stageId || '',
                    countryCode: filters?.countryCode || '',
                  }
                : {
                    stageId: '',
                    countryCode: '',
                  }
            }
            selectedFilter={(values) => handleFilterChange(values)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="table_outer_div">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 header-txt">
                  <h5>MOU LIST</h5>
                </div>
              </div>
            </div>

            {loading ? (
              <Loader />
            ) : (
              <>
                <AppTable
                  head={[
                    '',
                    /*'Name',*/
                    'Partner institute',
                    '',
                    'Coordinating faculty',
                    'Expiring Date',
                    '',
                  ]}
                  data={getMouTableData()}
                  limit={10}
                  pagination={false}
                />
                {meta.length > 10 && (
                  <AppPagination
                    dataLength={meta.length}
                    callBack={handleCallBack}
                    currentPage={~~(meta.from / 10) + 1}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  getMouList: () => getMouList(state),
  getApplicationTypeDetails: (applicationTypeId: string) =>
    getApplicationType(state, applicationTypeId),
  getStageDetails: (stageId: string) => getStage(state, stageId),
  getUniversityDetails: (universityCode: string) =>
    getUniversity(state, universityCode),
  getFacultyDetails: (facultyCode: string) => getFaculty(state, facultyCode),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchMous: (param?: MousRequest) => {
    dispatch(organizationActions.fetchMouList(param ? { ...param } : {}));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MouPage);
