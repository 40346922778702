import FacultyListPage from '../pages/FacultyListPage';
import FacultyViewPage from '../pages/FacultyViewPage';

export const FACULTIES = '/faculties';
export const FACULTY = `${FACULTIES}/:facultyId`;

export default [
  {
    path: FACULTIES,
    component: FacultyListPage,
    authenticated: true,
    permissions: [],
    exact: true,
  },
  {
    path: FACULTY,
    component: FacultyViewPage,
    authenticated: true,
    permissions: [],
    exact: true,
  },
];
