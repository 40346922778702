import * as React from 'react';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;
`;

const Loader: React.FC = () => (
  <Container>
    <Spinner animation="border" variant="primary" />
  </Container>
);

export default Loader;
