import { Observable } from 'rxjs';

import { DELETE, GET, POST, PUT } from './baseApi';
import { UpdatePasswordViewModel, User } from '../../models/user';
import {
  AuthenticationResponse,
  LoginRequest,
  SignUpRequest,
  NewAuth,
  CreateAuthRequest,
  ResetPasswordViewModel,
  ResetPasswordUser,
} from '../../models/auth';

const USER_ENDPOINT = '/user/';
const USER_LOGIN_ENDPOINT = `${USER_ENDPOINT}login`;
const USER_SIGNUP_ENDPOINT = `${USER_ENDPOINT}signUp`;
const NEW_AUTH_ENDPOINT = `${USER_ENDPOINT}toSignUp`;
const USER_UPDATE_PASSWORD = `${USER_ENDPOINT}changePassword`;
const USER_RESET_PASSWORD = `${USER_ENDPOINT}resetPassword`;

export const getUsers = (): Observable<Array<User>> => GET(USER_ENDPOINT);

export const getUser = (userId: string): Observable<User> =>
  GET(`${USER_ENDPOINT}${userId}`);

export const createUser = (auth: CreateAuthRequest): Observable<NewAuth> =>
  POST(USER_ENDPOINT, { ...auth });

export const updateUser = (user: User): Observable<User> =>
  PUT(`${USER_ENDPOINT}${user.userId}`, { ...user });

export const authenticateUser = (
  auth: LoginRequest
): Observable<AuthenticationResponse> => POST(USER_LOGIN_ENDPOINT, { ...auth });

export const signUpUser = (auth: SignUpRequest): Observable<User> =>
  POST(USER_SIGNUP_ENDPOINT, { ...auth });

export const getNewAuths = (): Observable<Array<NewAuth>> =>
  GET(NEW_AUTH_ENDPOINT);

export const getNewAuthByToken = (token: string): Observable<NewAuth> =>
  GET(`${NEW_AUTH_ENDPOINT}/${token}`);

export const updatePassword = (
  userId: string,
  password: UpdatePasswordViewModel
): Observable<null> =>
  PUT(`${USER_UPDATE_PASSWORD}/${userId}`, { ...password });

export const resetPasswordApply = (email: string): Observable<null> =>
  POST(USER_RESET_PASSWORD, { email });

export const getUserByResetToken = (
  confirmationToken: string
): Observable<ResetPasswordUser> =>
  GET(`${USER_RESET_PASSWORD}/${confirmationToken}`);

export const resetPassword = (
  token: string,
  payload: ResetPasswordViewModel
): Observable<null> => PUT(`${USER_RESET_PASSWORD}/${token}`, { ...payload });

export const deleteUser = (userId: string): Observable<null> =>
  DELETE(`${USER_ENDPOINT}${userId}`);
