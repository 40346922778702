import React, { useEffect, useState } from 'react';
import { AppTable, Loader } from '../../../shared/components';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { notificationActions } from '../../../app/redux/notification.slice';
import { getUserRole } from '../../../app/redux/appSelector';

import './UserView.css';
import { getUser } from '../../../services/api/userApi';
import { User } from '../../../models/user';
import { RootState } from '../../../app/redux/rootReducer';
import { renderRoleTd } from '../../../utils/roleGetFunction';
import { Table } from 'react-bootstrap';
import { getUserRole as getLoggedUserRole } from '../../auth/redux/authSelector';
import { roleTypes } from '../../../constants/userRole';
import { Button } from 'reactstrap';

interface ParamsProps {
  id: string;
}

const UserViewPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState<User>();
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const userRole = getLoggedUserRole(state);

  const { id } = useParams<ParamsProps>();

  useEffect(() => {
    setIsLoading(true);
    if (id) {
      getUser(id).subscribe(
        (response: User) => {
          setUserDetails(response);
          setIsLoading(false);
        },
        (error: Error) => {
          setIsLoading(false);
          dispatch(
            notificationActions.setToast({
              severity: 'danger',
              message: error.message,
              title: `Error: Couldn't Fetch User Details`,
            })
          );
        }
      );
    }
  }, []);

  // const getUserData = () => {
  //   const array: Array<JSX.Element> = [];
  //   {
  //     {
  //       array.push(
  //         <tr>
  //           <td>
  //             <img src="/images/users/Avatar.png" className="image"></img>
  //           </td>
  //           <td>
  //             <tr>
  //               <td>First Name</td>
  //               <td>{userDetails?.firstName}</td>
  //             </tr>
  //             <tr>
  //               <td>Last Name</td>
  //               <td>{userDetails?.lastName}</td>
  //             </tr>
  //             <tr>
  //               <td>Email</td>
  //               <td>{userDetails?.email}</td>
  //             </tr>
  //             <tr>
  //               <td>Role</td>
  //               {renderRoleTd(
  //                 userDetails &&
  //                   getUserRole(state, userDetails.roleId)?.roleName
  //               )}
  //             </tr>
  //             <tr>
  //               <td>Created at</td>
  //               <td>{userDetails?.createdAt}</td>
  //             </tr>
  //           </td>
  //         </tr>
  //       );
  //     }
  //   }

  //   return array;
  // };

  return (
    <div className="container main-container-div">
      {isLoading ? (
        <Loader />
      ) : (
        userDetails && (
          <div className="table_outer_div table_user_view">
            <div className="container">
              <div className="row align-items-start">
                <div className="col header-txt">
                  <h5>
                    {userDetails.firstName} {userDetails.lastName}
                  </h5>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col">
                  <Table borderless>
                    <tbody>
                      <tr>
                        <td className="profile-img-td">
                          <img src="/images/users/test.jpg" alt="" />
                        </td>
                        <td>
                          <Table borderless>
                            <tbody>
                              <tr>
                                <td>
                                  <img
                                    src="/images/users/Avatar.png"
                                    className="image"
                                  ></img>
                                </td>
                                <td>
                                  <tr>
                                    <td>First Name</td>
                                    <td>{userDetails?.firstName}</td>
                                  </tr>
                                  <tr>
                                    <td>Last Name</td>
                                    <td>{userDetails?.lastName}</td>
                                  </tr>
                                  <tr>
                                    <td>Email</td>
                                    <td>{userDetails?.email}</td>
                                  </tr>
                                  <tr>
                                    <td>Role</td>
                                    {renderRoleTd(
                                      userDetails &&
                                        getUserRole(state, userDetails.roleId)
                                          ?.roleName
                                    )}
                                  </tr>
                                  <tr>
                                    <td>Created at</td>
                                    <td>{userDetails?.createdAt}</td>
                                  </tr>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default UserViewPage;
