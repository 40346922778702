import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { RootState } from '../../../../app/redux/rootReducer';
import { User } from '../../../../models/user';
import { AppTable } from '../../../../shared/components';
import { MdViewHeadline } from 'react-icons/md';
import { getUserList } from '../../../../app/redux/appSelector';
import { Badge } from 'reactstrap';
import { UserRole } from '../../../../models/userRole';
import { getUserRole } from '../../../../app/redux/appSelector';
import { renderRoleTd } from '../../../../utils/roleGetFunction';

interface UserListProps {
  users: Array<User>;
  getRole: (id: string) => UserRole | undefined;
}

const UserList: React.FC<UserListProps> = ({
  users,
  getRole,
}: UserListProps) => {
  const getUserData = () => {
    const array: Array<JSX.Element> = [];
    {
      {
        users.map((item, i = 1) =>
          array.push(
            <tr key={item.userId}>
              <th scope="row">{i + 1}</th>
              <td>{item.firstName}</td>
              <td>{item.lastName}</td>
              <td>{item.email}</td>
              {renderRoleTd(getRole(item.roleId)?.roleName)}
              <td>{item.createdAt}</td>
              <td>
                <Link
                  to={'users/'.concat(item.userId)}
                  className="add-new-link-btn-thin-border ft-size-16"
                >
                  <MdViewHeadline size={20} />
                </Link>
              </td>
            </tr>
          )
        );
      }
    }

    return array;
  };

  return (
    <div className="table_outer_div">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 header-txt">
            <h5>USER LIST</h5>
          </div>
        </div>
      </div>

      <AppTable
        head={[
          '#',
          'First Name',
          'Last Name',
          'Email',
          'Role',
          'Created at',
          '',
        ]}
        data={getUserData()}
        limit={5}
        pagination={true}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  users: getUserList(state),
  getRole: (id: string) => getUserRole(state, id),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
