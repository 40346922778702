import { Observable, concat, from, of } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import { ofType } from 'redux-observable';
import { filter, mergeMap, catchError } from 'rxjs/operators';

import { authActions } from './authSlice';
import { appAction } from '../../../app/redux/appSlice';
import { authenticateUser } from '../../../services/api/userApi';
import { setBearerToken } from '../../../services/api/baseApi';
import { navigationActions } from '../../../app/redux/navigationSlice';
import storage from '../../../services/storage/localStorage';
import authConstants from '../../../constants/storage';
import { notificationActions } from '../../../app/redux/notification.slice';
import { getUserRole } from '../../../services/api/userRoleApi';
import { roleTypes } from '../../../constants/userRole';
import { organizationActions } from '../../dashboard/redux/organizationSlice';

const login = (action$: Observable<Action>): Observable<Action> =>
  // eslint-disable-next-line
  action$.pipe(
    ofType(authActions.login),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    filter(authActions.login.match),
    mergeMap((action) =>
      concat(
        of(appAction.setLoading(true)),
        from(authenticateUser(action.payload)).pipe(
          mergeMap((response) =>
            of(
              authActions.authenticateUserSuccess({
                ...response,
              }),
              navigationActions.setNavigationDetails({
                pathname: '/',
                queryParams: {},
              })
            )
          ),
          catchError((error: Error, source) =>
            of(
              appAction.loadingSuccess(),
              notificationActions.setToast({
                severity: 'danger',
                message: error.message,
                title: `Error: Invalid User Credentials`,
              })
            )
          )
        )
      )
    )
  );

const fetchUserRole = (action$: Observable<Action>): Observable<Action> =>
  // eslint-disable-next-line
  action$.pipe(
    ofType(authActions.fetchUserRole),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    filter(authActions.fetchUserRole.match),
    mergeMap((action) =>
      from(getUserRole(action.payload)).pipe(
        mergeMap((response) => {
          if (response.roleName != roleTypes.SUPER_ADMIN) {
            return concat(of(authActions.setUserRole(response)));
          }
          return concat(of(authActions.setUserRole(response)));
        })
      )
    ),
    catchError((error: Error) =>
      of(
        notificationActions.setToast({
          severity: 'danger',
          message: error.message,
          title: `Error: Couldn't Fetch User Role`,
        })
      )
    )
  );

const loginSuccess = (action$: Observable<Action>): Observable<Action> =>
  // eslint-disable-next-line
  action$.pipe(
    ofType(authActions.authenticateUserSuccess),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    filter(authActions.authenticateUserSuccess.match),
    mergeMap((action) => {
      const user = { ...action.payload };
      setBearerToken(user.token);
      storage.set(authConstants.AUTH_RESPONSE, user);
      return of(
        appAction.fetchUserRoleList(),
        authActions.fetchUserRole(user.roleId),
        organizationActions.fetchApplicationTypeList(),
        //organizationActions.fetchCoordinatorList(),
        organizationActions.fetchCountryList(),
        organizationActions.fetchFacultyList(),
        organizationActions.fetchStageList(),
        organizationActions.fetchUniversityList(),
        appAction.fetchUserList(),
        appAction.setLoading(false)
      );
    })
  );

export const authEpic = [login, loginSuccess, fetchUserRole];
