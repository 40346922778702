/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useCallback, useEffect } from 'react';
import { useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Dispatch } from 'redux';
import { appAction } from '../../redux/appSlice';
import { navigationActions } from '../../redux/navigationSlice';
import { RootState } from '../../redux/rootReducer';

interface Props {
  setLoading: () => void;
  clearNavigation: () => void;
}

const GlobalNavigation: React.FC<Props> = ({
  setLoading,
  clearNavigation,
}: Props) => {
  const { pathname, queryParams } = useSelector(
    (state: RootState) => state.navigation
  );
  const history = useHistory();

  const navigate = useCallback(() => {
    let query = {};
    if (queryParams) {
      query = {
        ...queryParams,
      };
    }
    pathname && history.push(pathname);
    clearNavigation();
    setLoading();
  }, [pathname, queryParams]);

  useEffect(() => {
    if (pathname) {
      navigate();
    }
  }, [pathname]);

  return <div />;
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setLoading: () => {
    dispatch(appAction.loadingSuccess());
  },
  clearNavigation: () => {
    dispatch(navigationActions.clearNavigationDetails());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalNavigation);
