import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

import { AppButton, AppInput } from '../../../shared/forms/components';
import { Country } from '../../../models/country';
//import {NewAuth } from '../../../models/auth';
//import { notificationActions } from '../../../app/redux/notification.slice';
import { useHistory } from 'react-router';

const getInitialValues = () => ({
  countryName: '',
  countryCode: '',
});

const validationSchema = Yup.object().shape({
  countryName: Yup.string()
    .min(3, 'should be more than three letters')
    .max(20, 'Should be less than 20 letters')
    .required('This is a required field'),
  countryCode: Yup.string()
    .min(2, 'Should be 2 Capital Letters "Insert the Alpha-2 Code"')
    .max(2, 'Should be 2 Capital Letters "Insert the Alpha-2 Code"')
    .matches(
      /^[A-Z]*$/,
      'Should be 2 Capital Letters "Insert the Alpha-2 Code"'
    )
    .required('This is a required field'),
});

/*interface Props {
  auth: AddCountryAuth;
}*/
const onSubmitHandler = (values: Country) => {
  //AddCountry({});
};

const AddCountryForm: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Formik
      initialValues={getInitialValues()}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      <Form>
        <div className="d-flex justify-content-center text-center" />
        <AppInput
          name="countryName"
          label="Country Name"
          placeholder="Country Name"
          type="Text"
        />
        <AppInput
          name="countryCode"
          label="Country Code"
          placeholder="Country Code"
          type="text"
        />
        <AppButton type="submit" text="Add" />
      </Form>
    </Formik>
  );
};

export default AddCountryForm;
