/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Observable, concat, from, of } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import { map, filter, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { organizationActions } from './organizationSlice';
import { getMous } from '../../../services/api/mouApi';
import { notificationActions } from '../../../app/redux/notification.slice';
import { getApplicationTypes } from '../../../services/api/applicationTypeApi';
import { getCoordinators } from '../../../services/api/coordinatorApi';
import { getCountries } from '../../../services/api/countryApi';
import { getFaculties } from '../../../services/api/facultyApi';
import { getStages } from '../../../services/api/stageApi';
import { getUniversities } from '../../../services/api/universityApi';

const fetchApplicationTypeEpic = (
  action$: Observable<Action>
): Observable<Action> =>
  // eslint-disable-next-line
  action$.pipe(
    ofType(organizationActions.fetchApplicationTypeList),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    filter(organizationActions.fetchApplicationTypeList.match),
    mergeMap((action) =>
      concat(
        from(getApplicationTypes()).pipe(
          map((response) =>
            organizationActions.fetchApplicationTypeListSuccess(response)
          )
        )
      )
    ),
    catchError((error: Error) =>
      of(
        notificationActions.setToast({
          severity: 'danger',
          message: error.message,
          title: `Error: Couldn't Fetch Application Type`,
        })
      )
    )
  );

const fetchCoordinatorEpic = (
  action$: Observable<Action>
): Observable<Action> =>
  // eslint-disable-next-line
  action$.pipe(
    ofType(organizationActions.fetchCoordinatorList),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    filter(organizationActions.fetchCoordinatorList.match),
    mergeMap((action) =>
      concat(
        from(getCoordinators()).pipe(
          map((response) =>
            organizationActions.fetchCoordinatorListSuccess(response)
          )
        )
      )
    ),
    catchError((error: Error) =>
      of(
        notificationActions.setToast({
          severity: 'danger',
          message: error.message,
          title: `Error: Couldn't Fetch Coordinator`,
        })
      )
    )
  );

const fetchCountryEpic = (action$: Observable<Action>): Observable<Action> =>
  // eslint-disable-next-line
  action$.pipe(
    ofType(organizationActions.fetchCountryList),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    filter(organizationActions.fetchCountryList.match),
    mergeMap((action) =>
      concat(
        from(getCountries()).pipe(
          map((response) =>
            organizationActions.fetchCountryListSuccess(response)
          )
        )
      )
    ),
    catchError((error: Error) =>
      of(
        notificationActions.setToast({
          severity: 'danger',
          message: error.message,
          title: `Error: Couldn't Fetch Country`,
        })
      )
    )
  );

const fetchStageEpic = (action$: Observable<Action>): Observable<Action> =>
  // eslint-disable-next-line
  action$.pipe(
    ofType(organizationActions.fetchStageList),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    filter(organizationActions.fetchStageList.match),
    mergeMap((action) =>
      concat(
        from(getStages()).pipe(
          map((response) => organizationActions.fetchStageListSuccess(response))
        )
      )
    ),
    catchError((error: Error) =>
      of(
        notificationActions.setToast({
          severity: 'danger',
          message: error.message,
          title: `Error: Couldn't Fetch Stages`,
        })
      )
    )
  );

const fetchFacultyEpic = (action$: Observable<Action>): Observable<Action> =>
  // eslint-disable-next-line
  action$.pipe(
    ofType(organizationActions.fetchFacultyList),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    filter(organizationActions.fetchFacultyList.match),
    mergeMap((action) =>
      concat(
        from(getFaculties()).pipe(
          map((response) =>
            organizationActions.fetchFacultyListSuccess(response)
          )
        )
      )
    ),
    catchError((error: Error) =>
      of(
        notificationActions.setToast({
          severity: 'danger',
          message: error.message,
          title: `Error: Couldn't Fetch Faculty`,
        })
      )
    )
  );

const fetchUniversityEpic = (action$: Observable<Action>): Observable<Action> =>
  // eslint-disable-next-line
  action$.pipe(
    ofType(organizationActions.fetchUniversityList),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    filter(organizationActions.fetchUniversityList.match),
    mergeMap((action) =>
      concat(
        from(getUniversities()).pipe(
          map((response) =>
            organizationActions.fetchUniversityListSuccess(response)
          )
        )
      )
    ),
    catchError((error: Error) =>
      of(
        notificationActions.setToast({
          severity: 'danger',
          message: error.message,
          title: `Error: Couldn't Fetch Universities`,
        })
      )
    )
  );

const fetchMouListEpic = (action$: Observable<Action>): Observable<Action> =>
  // eslint-disable-next-line
  action$.pipe(
    ofType(organizationActions.fetchMouList),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    filter(organizationActions.fetchMouList.match),
    mergeMap((action) =>
      concat(
        from(getMous(action.payload)).pipe(
          map((response) => organizationActions.fetchMouListSuccess(response))
        )
      )
    ),
    catchError((error: Error) =>
      of(
        notificationActions.setToast({
          severity: 'danger',
          message: error.message,
          title: `Error: Couldn't Fetch Mous`,
        })
      )
    )
  );

export const organizationEpic = [
  fetchApplicationTypeEpic,
  fetchCoordinatorEpic,
  fetchCountryEpic,
  fetchFacultyEpic,
  fetchStageEpic,
  fetchUniversityEpic,
  fetchMouListEpic,
];
