import React, { useState, useEffect } from 'react';
import { Table } from 'reactstrap';

import { AppPagination } from '..';

interface Props {
  head: Array<string>;
  data: Array<JSX.Element>;
  limit?: number;
  pagination?: boolean;
  dark?: boolean;
  striped?: boolean;
  hover?: boolean;
  responsive?: boolean;
  startingPage?: number;
  pageOnChange?: (page: number, from: number, to: number) => void;
}

interface StateProps {
  page: number;
  from: number;
  to: number;
}

const AppTable: React.FC<Props> = ({
  head,
  data,
  limit = 10,
  pagination = true,
  dark = false,
  striped = false,
  hover = true,
  responsive = true,
  startingPage = 1,
  pageOnChange,
}: Props) => {
  const [paginationData, setPaginationData] = useState<StateProps>({
    page: 1,
    from: 1,
    to: limit,
  });

  useEffect(() => {
    setPaginationData({
      page: startingPage,
      from: (startingPage - 1) * limit + 1,
      to:
        data.length > startingPage * limit ? startingPage * limit : data.length,
    });
  }, [data.length]);

  const callBack = (page: number, from: number, to: number) => {
    setPaginationData({ page, from, to });
    pageOnChange && pageOnChange(page, from, to);
  };

  const getTableBody = () => {
    if (pagination) {
      const { from, to } = paginationData;
      return data.slice(from - 1, to);
    } else {
      return data;
    }
  };

  return (
    <div>
      <Table BorderLess dark={dark} striped={striped} responsive={responsive}>
        <thead>
          <tr>
            {head.map((item) => (
              <th key={item}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>{getTableBody()}</tbody>
      </Table>
      {data.length === 0 && <div>No data</div>}
      <div className="row">
        <div className="col m-2">
          {pagination && data.length > limit && (
            <AppPagination
              currentPage={startingPage}
              dataLength={data.length}
              callBack={callBack}
              limit={limit}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AppTable;
