import React, { useState, useEffect } from 'react';
import { AiOutlineHome } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import { AppModal, AppTable } from '../../../shared/components';
import AddFacultyForm from '../form/AddFacultyForm';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { MdViewHeadline } from 'react-icons/md';
import { getFaculties } from '../../dashboard/redux/organizationSelector';
import { RootState } from '../../../app/redux/rootReducer';
import { Dispatch } from 'redux';
import { organizationActions } from '../../dashboard/redux/organizationSlice';
import { Faculty } from '../../../models/faculty';

interface FacultyListProps {
  faculties: Array<Faculty>;
  fetchFaculties: () => void;
}

const FacultyListPage: React.FC<FacultyListProps> = ({
  faculties,
  fetchFaculties,
}: FacultyListProps) => {
  const [showModal, setShowModal] = useState(false);
  const [refreshState, setRefreshState] = useState(false);
  const dispatch = useDispatch();

  const handleModalToggle = () => {
    setShowModal(!showModal);
  };

  const getFacultyTableData = () => {
    const array: Array<JSX.Element> = [];

    {
      faculties.map((item, i = 1) =>
        array.push(
          <tr>
            <th scope="row">{i + 1}</th>
            <td>{item.name}</td>
            <td>
              <a href={`tel:${item.phoneNumber}`}>{item.phoneNumber}</a>
            </td>
            <td>{item.email}</td>
            {/*<td>
              <Link
                to={'faculties/'.concat(item.facultyId)}
                className="add-new-link-btn-thin-border ft-size-16"
              >
                <MdViewHeadline size={20} />
              </Link>
            </td>*/}
          </tr>
        )
      );
    }

    return array;
  };

  useEffect(() => {
    fetchFaculties();
  }, []);

  const handleOnSuccessCreateFaculty = () => {
    handleModalToggle();
    setRefreshState(!refreshState);
    fetchFaculties();
  };

  return (
    <>
      <div className="container pt-3 main-container-div">
        <div>
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-end">
              <div>
                <a
                  onClick={handleModalToggle}
                  className="add-new-link-btn ft-size-16"
                >
                  <AiOutlineHome size={18} /> Add Faculty
                </a>
                <AppModal
                  onClose={handleModalToggle}
                  show={showModal}
                  title="Add Faculty"
                >
                  <AddFacultyForm
                    createFacultySuccessCallBack={handleOnSuccessCreateFaculty}
                  />
                </AppModal>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="table_outer_div">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 header-txt">
                    <h5>Faculty LIST</h5>
                  </div>
                </div>
              </div>

              <AppTable
                head={['', 'Faculty Name', 'Phone Number', 'Email']}
                data={getFacultyTableData()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  faculties: getFaculties(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchFaculties: () => {
    dispatch(organizationActions.fetchFacultyList());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FacultyListPage);
