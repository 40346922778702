import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AppTable } from '../../../shared/components';

interface ParamsProps {
  coordinatorId: string;
}
const CoordinatorViewPage: React.FC = () => {
  const getCoordinatorViewTableData = () => {
    const array: Array<JSX.Element> = [];
    return array;
  };
  const { coordinatorId } = useParams<ParamsProps>();
  console.log(coordinatorId);
  return (
    <>
      <div className="container pt-3 main-container-div">
        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="table_outer_div">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 header-txt">
                    <h5>MoUs in Charge</h5>
                  </div>
                </div>
              </div>

              <AppTable
                head={[
                  '',
                  'Name',
                  'Partner Institute',
                  '',
                  'Coordinating Faculty',
                  'Expiring Date',
                  '',
                ]}
                data={getCoordinatorViewTableData()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CoordinatorViewPage;
