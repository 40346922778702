import { Dashboard } from '../pages';

import UpdatePasswordPage from '../pages/dashboard/UpdatePasswordPage';

export const DASHBOARD = '/';
export const PROFILE = '/profile';
export const UPDATE_PASSWORD = '/update-password';

export default [
  {
    path: DASHBOARD,
    component: Dashboard,
    authenticated: true,
    permissions: [],
    exact: true,
  },
  {
    path: UPDATE_PASSWORD,
    component: UpdatePasswordPage,
    authenticated: true,
    permissions: [],
    exact: true,
  },
];
