import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ResetPasswordForm from '../forms/ResetPasswordForm';

import './login.css';

interface ParamsProps {
  confirmationToken: string;
}

const ResetPassword: React.FC = () => {
  const { confirmationToken } = useParams<ParamsProps>();

  return (
    <div>
      {/* <TopBar /> */}
      <div className="container container-width-set-none">
        <div className="row justify-content-center">
          <div className="col-sm-6 margin-top-set-5vh">
            <div className="row justify-content-md-center">
              <div className="col-sm-8">
                <ResetPasswordForm confirmationToken={confirmationToken} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResetPassword;
