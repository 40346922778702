import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { RootState } from '../../../app/redux/rootReducer';
import { AuthenticationResponse } from '../../../models/auth';
import { getAuth } from '../../../modules/auth/redux/authSelector';
import { LOGIN } from '../../../modules/auth/routes';

export interface ProtectedRouteProps {
  auth: AuthenticationResponse | null;
  component: React.ComponentType;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  auth,
  component: Component,
}: ProtectedRouteProps) => (auth ? <Component /> : <Redirect to={LOGIN} />);

const mapStateToProps = (state: RootState) => ({
  auth: getAuth(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
