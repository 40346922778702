import { Observable } from 'rxjs';

import { GET, POST, PUT } from './baseApi';
import { University } from '../../models/university';

const UNIVERSITY_ENDPOINT = '/university/';

export const getUniversities = (): Observable<Array<University>> =>
  GET(UNIVERSITY_ENDPOINT);

export const getUniversity = (universityCode: string): Observable<University> =>
  GET(`${UNIVERSITY_ENDPOINT}${universityCode}`);

export const createUniversity = (
  university: University
): Observable<University> => POST(UNIVERSITY_ENDPOINT, { ...university });

export const updateUniversity = (
  university: University
): Observable<University> =>
  PUT(`${UNIVERSITY_ENDPOINT}${university.universityCode}`, {
    ...university,
  });
