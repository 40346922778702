import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

import { AppButton, AppInput } from '../../../shared/forms/components';
import { SignUpUserViewModel } from '../../../models/user';
import { NewAuth } from '../../../models/auth';
import { signUpUser } from '../../../services/api/userApi';
import { notificationActions } from '../../../app/redux/notification.slice';
import { useHistory } from 'react-router';
import { LOGIN } from '../../auth/routes';

const getInitialValues = (values: NewAuth) => ({
  firstName: '',
  lastName: '',
  password: '',
  ...values,
});

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('This is a required field'),
  lastName: Yup.string().required('This is a required field'),
  password: Yup.string().required('This is a required field'),
});

interface Props {
  auth: NewAuth;
}

const SignUpUserForm: React.FC<Props> = ({ auth }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmitHandler = (values: SignUpUserViewModel) => {
    signUpUser({
      ...values,
      token: auth.confirmationToken,
      roleId: auth.roleId,
    }).subscribe(
      (response) => {
        history.push(LOGIN);
        dispatch(
          notificationActions.setToast({
            severity: 'success',
            message: 'Successfully sign up',
            title: 'Success',
          })
        );
      },
      (error: Error) => {
        dispatch(
          notificationActions.setToast({
            severity: 'danger',
            message: error.message,
            title: 'Error: Failed To Sign Up',
          })
        );
      }
    );
  };

  return (
    <Formik
      initialValues={getInitialValues(auth)}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      <Form>
        <div className="d-flex justify-content-center mb-5 text-center" />
        <h2 className="title-tag-style my-2 text-left mb-4">Sign Up</h2>
        <AppInput
          name="email"
          label="Email*"
          placeholder="Email*"
          type="email"
          readOnly
        />
        <AppInput
          name="firstName"
          label="First Name*"
          placeholder="First Name*"
          type="text"
        />
        <AppInput
          name="lastName"
          label="Last Name*"
          placeholder="Last Name*"
          type="text"
        />
        <AppInput
          name="password"
          label="Password*"
          placeholder="Password*"
          type="password"
        />
        <AppButton type="submit" text="Sign In" />
      </Form>
    </Formik>
  );
};

export default SignUpUserForm;
