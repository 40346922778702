import { Observable } from 'rxjs';

import { GET, POST, PUT } from './baseApi';
import { Faculty } from '../../models/faculty';

const FACULTY_ENDPOINT = '/faculty/';

export const getFaculties = (): Observable<Array<Faculty>> =>
  GET(FACULTY_ENDPOINT);

export const getFaculty = (facultyCode: string): Observable<Faculty> =>
  GET(`${FACULTY_ENDPOINT}${facultyCode}`);

export const createFaculty = (faculty: Faculty): Observable<Faculty> =>
  POST(FACULTY_ENDPOINT, { ...faculty });

export const updateFaculty = (faculty: Faculty): Observable<Faculty> =>
  PUT(`${FACULTY_ENDPOINT}${faculty.facultyId}`, {
    ...faculty,
  });
