import React, { useState, useEffect } from 'react';

import { Badge } from 'reactstrap';
import { mouStages } from '../constants/mouStages';

export function renderStage(value: string | undefined) {
  switch (value) {
    case mouStages.STAGE_1:
      return (
        <th>
          <Badge color="primary">Start</Badge>
        </th>
      );
    case mouStages.STAGE_2:
      return (
        <th>
          <Badge color="secondary">Legal Div</Badge>
        </th>
      );
    case mouStages.STAGE_3:
      return (
        <th>
          <Badge color="success">Faculty Board</Badge>
        </th>
      );
    case mouStages.STAGE_4:
      return (
        <th>
          <Badge color="danger">ADPC</Badge>
        </th>
      );
    case mouStages.STAGE_5:
      return (
        <th>
          <Badge color="warning">Faculty</Badge>
        </th>
      );
    case mouStages.STAGE_6:
      return (
        <th>
          <Badge color="info">Senate</Badge>
        </th>
      );
    case mouStages.STAGE_7:
      return (
        <th>
          <Badge color="light">Council</Badge>
        </th>
      );
    case mouStages.STAGE_8:
      return (
        <th>
          <Badge color="dark">UGC</Badge>
        </th>
      );
    case mouStages.STAGE_9:
      return (
        <th>
          <Badge color="primary">MOE</Badge>
        </th>
      );
    case mouStages.STAGE_10:
      return (
        <th>
          <Badge color="secondary">NPD</Badge>
        </th>
      );
    case mouStages.STAGE_11:
      return (
        <th>
          <Badge color="success">ERD</Badge>
        </th>
      );
    case mouStages.STAGE_12:
      return (
        <th>
          <Badge color="danger">MOFA</Badge>
        </th>
      );
    case mouStages.STAGE_13:
      return (
        <th>
          <Badge color="warning">AG</Badge>
        </th>
      );
    case mouStages.STAGE_14:
      return (
        <th>
          <Badge color="info">Cabinet</Badge>
        </th>
      );
    case mouStages.STAGE_15:
      return (
        <th>
          <Badge color="dark">OnGoing</Badge>
        </th>
      );
  }
}
