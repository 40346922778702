import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { connect, useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import { CreateUserForm as CreateUserFormViewModel } from '../../../models/user';
import { AppButton, AppInput } from '../../../shared/forms/components';
import AppDropdown from '../../../shared/forms/components/AppDropDown/AppDropDown.component';
import { getUserRoles } from '../../../app/redux/appSelector';
import { RootState } from '../../../app/redux/rootReducer';
import { UserRole } from '../../../models/userRole';
import { filterUserRoles } from '../../../utils/filterUtils';
import { roleTypes } from '../../../constants/userRole';
import { getAuth } from '../../auth/redux/authSelector';
import { createUser } from '../../../services/api/userApi';
import { notificationActions } from '../../../app/redux/notification.slice';

const initialValues = {
  email: '',
  roleId: '',
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .required('This is a required field'),
  roleId: Yup.string().required('This is a required field'),
});

interface Props {
  userRoles: Array<UserRole>;
  createUserSuccessCallBack?: () => void;
}

const CreateSuperAdminForm: React.FC<Props> = ({
  userRoles,
  createUserSuccessCallBack,
}: Props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandler = (values: CreateUserFormViewModel) => {
    setIsLoading(true);
    createUser({ ...values }).subscribe(
      (response) => {
        dispatch(
          notificationActions.setToast({
            severity: 'success',
            message: 'Successfully created user',
            title: 'Success',
          })
        );
        createUserSuccessCallBack && createUserSuccessCallBack();
      },
      (error: AxiosError) => {
        dispatch(
          notificationActions.setToast({
            severity: 'danger',
            message: error.message,
            title: 'Error: Failed To Create User',
          })
        );
        setIsLoading(false);
      }
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      <Form>
        <AppInput
          name="email"
          label="Email*"
          placeholder="Email*"
          type="email"
          loading={isLoading}
        />
        <AppDropdown
          name="roleId"
          label="Role*"
          placeholder="Enter Role"
          loading={isLoading}
        >
          <option defaultChecked value="">
            --Please choose an option--
          </option>
          {userRoles &&
            userRoles.map((userRole) => (
              <option key={userRole.roleId} value={userRole.roleId}>
                {userRole.roleName}
              </option>
            ))}
        </AppDropdown>
        <AppButton type="submit" text="Create" loading={isLoading} />
      </Form>
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => ({
  userRoles: filterUserRoles(getUserRoles(state), [
    roleTypes.SUPER_ADMIN,
    roleTypes.ADMIN,
  ]),
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSuperAdminForm);
