import PartnerListPage from '../pages/PartnerListPage';
import PartnerViewPage from '../pages/PartnerViewPage';

export const PARTNERS = '/partners';
export const PARTNER = `${PARTNERS}/:partnerId`;

export default [
  {
    path: PARTNERS,
    component: PartnerListPage,
    authenticated: true,
    permissions: [],
    exact: true,
  },
  {
    path: PARTNER,
    component: PartnerViewPage,
    authenticated: true,
    permissions: [],
    exact: true,
  },
];
