import React from 'react';

import UpdatePasswordForm from '../../../user/forms/UpdatePasswordForm';

const UpdatePasswordPage: React.FC = () => (
  <div className="container">
    <div className="row">
      <div className="col-lg-12 header-txt">
        <h5>Change Password</h5>
        <UpdatePasswordForm />
      </div>
    </div>
  </div>
);

export default UpdatePasswordPage;
