import { Observable } from 'rxjs';

import { GET, POST, PUT } from './baseApi';
import { Stage } from '../../models/stage';

const STAGE_ENDPOINT = '/stage/';

export const getStages = (): Observable<Array<Stage>> => GET(STAGE_ENDPOINT);

export const getStage = (stageId: string): Observable<Stage> =>
  GET(`${STAGE_ENDPOINT}${stageId}`);

export const createStage = (stage: Stage): Observable<Stage> =>
  POST(STAGE_ENDPOINT, { ...stage });

export const updateStage = (stage: Stage): Observable<Stage> =>
  PUT(`${STAGE_ENDPOINT}${stage.stageId}`, {
    ...stage,
  });
