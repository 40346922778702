import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import { AppButton, AppInput } from '../../../shared/forms/components';
import {
  Coordinator,
  CreateCoordinatorForm,
} from '../../../models/coordinator';
//import {NewAuth } from '../../../models/auth';
import { notificationActions } from '../../../app/redux/notification.slice';
import { useHistory } from 'react-router';
import { createCoordinator } from '../../../services/api/coordinatorApi';

import miscUtils from '../../../utils/miscUtils';

const getInitialValues = () => ({
  coordinatorName: '',
  coordinatorPhoneNum: '',
  coordinatorEmail: '',
});

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object().shape({
  coordinatorName: Yup.string()
    .max(30, 'maximum of 30 letters')
    .required('This is a required field'),
  coordinatorEmail: Yup.string()
    .email('Should be a valid Email')
    .required('This is a required field'),
  coordinatorPhoneNum: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('This is a required field'),
});

interface Props {
  createCoordinatorSuccessCallBack?: () => void;
}

const AddCoordinatorForm: React.FC<Props> = ({
  createCoordinatorSuccessCallBack,
}: Props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandler = (values: CreateCoordinatorForm) => {
    setIsLoading(true);

    const coordinatorId = miscUtils.generateUuid();

    createCoordinator({ ...values, coordinatorId }).subscribe(
      (response) => {
        dispatch(
          notificationActions.setToast({
            severity: 'success',
            message: 'Successfully created Coordinator',
            title: 'Success',
          })
        );
        createCoordinatorSuccessCallBack && createCoordinatorSuccessCallBack();
      },
      (error: AxiosError) => {
        dispatch(
          notificationActions.setToast({
            severity: 'danger',
            message: error.message,
            title: 'Error: Failed To Create Coordinator',
          })
        );
        setIsLoading(false);
      }
    );
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      <Form>
        <div className="d-flex justify-content-center text-center" />

        <AppInput
          name="coordinatorName"
          label="Coordinator Name"
          placeholder="Coordinator Name"
          type="text"
        />
        <AppInput
          name="coordinatorPhoneNum"
          label="Coordinator Phone Number"
          placeholder="Coordinator Phone Number"
          type="Text"
        />
        <AppInput
          name="coordinatorEmail"
          label="Coordinator Email"
          placeholder="Coordinator Email"
          type="email"
        />
        <AppButton type="submit" text="Add" />
      </Form>
    </Formik>
  );
};

export default AddCoordinatorForm;
