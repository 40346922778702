import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Alert, Badge, Table } from 'reactstrap';
import moment from 'moment-timezone';
import { Dispatch } from 'redux';

import {
  getApplicationType,
  getCoordinator,
  getCountry,
  getFaculty,
  getMouList,
  getStage,
  getUniversity,
} from '../../../dashboard/redux/organizationSelector';
import { AppModal, AppTable, Loader } from '../../../../shared/components';
import { RootState } from '../../../../app/redux/rootReducer';
import { ApplicationType } from '../../../../models/applicationType';
import { Stage } from '../../../../models/stage';
import { University } from '../../../../models/university';
import { Faculty } from '../../../../models/faculty';
import './MouViewPage.css';
import { Mou } from '../../../../models/mou';
import { getMou } from '../../../../services/api/mouApi';
import { notificationActions } from '../../../../app/redux/notification.slice';
import { DATE_FORMAT } from '../../../../constants/dateTime';
import { mouStages } from '../../../../constants/mouStages';
import { Country } from '../../../../models/country';
import { Coordinator } from '../../../../models/coordinator';
import { organizationActions } from '../../../dashboard/redux/organizationSlice';
import { renderStage } from '../../../../utils/stageGetFunction';
import AddMouForm from '../../form/AddMouForm';

interface ParamsProps {
  mouId: string;
}

interface Props {
  fetchCoordinators: () => void;
  getApplicationTypeDetails: (
    applicationTypeId: string
  ) => ApplicationType | undefined;
  getStageDetails: (stageId: string) => Stage | undefined;
  getUniversityDetails: (universityCode: string) => University | undefined;
  getFacultyDetails: (facultyCode: string) => Faculty | undefined;
  getCountryDetails: (countryCode: string) => Country | undefined;
  getCoordinatorDetails: (coordinatorId: string) => Coordinator | undefined;
}

const MouViewPage: React.FC<Props> = ({
  fetchCoordinators,
  getApplicationTypeDetails,
  getStageDetails,
  getUniversityDetails,
  getFacultyDetails,
  getCountryDetails,
  getCoordinatorDetails,
}: Props) => {
  const state = useSelector((state: RootState) => state);

  const { mouId } = useParams<ParamsProps>();
  const [mouDetails, setMouDetails] = useState<Mou>();
  const [countryDetails, setCountryDetails] = useState<Country>();
  const [facultyDetails, setFacultyDetails] = useState<Faculty>();
  const [universityDetails, setUniversityDetails] = useState<University>();

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    fetchCoordinators();

    if (mouId) {
      getMou(mouId).subscribe(
        (response: Mou) => {
          setMouDetails(response);

          setUniversityDetails(getUniversityDetails(response.universityCode));
          setFacultyDetails(getFacultyDetails(response.facultyId));
          universityDetails &&
            setCountryDetails(
              getCountryDetails(universityDetails?.countryCode)
            );

          setIsLoading(false);
          console.log(response);
        },
        (error: Error) => {
          setIsLoading(true);
          dispatch(
            notificationActions.setToast({
              severity: 'danger',
              message: error.message,
              title: `Error: Couldn't Fetch Mou Details`,
            })
          );
        }
      );
    }
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <div className="main-container-div">
      <div className="row m-2">
        <div className="col-lg-12">
          <div className="table_outer_div">
            <div className="row align-items-start pl-3 pr-3">
              <div className="col-sm-8 header-txt">
                <h5 className="float-left">{mouDetails?.mouName}</h5>
              </div>
              <div className="col header-txt">
                <div className="float-right">
                  {countryDetails && (
                    <img
                      src={`/images/country_flags/${countryDetails?.countryCode}.svg`}
                      alt={countryDetails?.countryName}
                      className="country-image-mou"
                    />
                  )}
                </div>
              </div>
              <div className="col header-txt">
                <div className="float-right">
                  {mouDetails &&
                    renderStage(
                      getStageDetails(mouDetails?.stageId)?.stageName
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row m-2">
        <div className="col-lg-5">
          <div className="table_outer_div">
            <div className="container">
              <Table BorderLess>
                <tbody>
                  <tr>
                    <td>
                      <b>File Number</b>
                    </td>
                    <td>{mouDetails?.fileNo}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Agreement Number</b>
                    </td>
                    <td>{mouDetails?.agreementNo}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Type</b>
                    </td>
                    <td>
                      {mouDetails &&
                        getApplicationTypeDetails(mouDetails?.applicationTypeId)
                          ?.applicationTypeName}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Starting Date</b>
                    </td>
                    <td>
                      {moment(mouDetails?.startDate)
                        .tz(moment.tz.guess())
                        .format(DATE_FORMAT)}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Signing Date</b>
                    </td>
                    <td>
                      {moment(mouDetails?.signingDate)
                        .tz(moment.tz.guess())
                        .format(DATE_FORMAT)}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Expiring Date</b>
                    </td>
                    <td>
                      {moment(mouDetails?.expiringDate)
                        .tz(moment.tz.guess())
                        .format(DATE_FORMAT)}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Grant Period</b>
                    </td>
                    <td>{mouDetails?.grantPeriod} Years</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Remarks</b>
                    </td>
                    <td>{mouDetails?.remarks}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>University</b>
                    </td>
                    <td>
                      {mouDetails &&
                        getUniversityDetails(mouDetails?.universityCode)
                          ?.universityName}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Faculty</b>
                    </td>
                    <td>
                      {mouDetails &&
                        getFacultyDetails(mouDetails.facultyId)?.name}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div className="col-lg-7">
          <div className="row coordinator-list-mou">
            <div className="col-lg-12 pt-3 pb-3">
              {mouDetails &&
              getCoordinatorDetails(mouDetails?.coordinatorOne)
                ?.coordinatorName !== undefined ? (
                <Link
                  to={`/coordinators/${mouDetails?.coordinatorOne}`}
                  style={{ textDecoration: 'none' }}
                >
                  <Alert className="alert-button-coordinator" color="secondary">
                    {
                      getCoordinatorDetails(mouDetails?.coordinatorOne)
                        ?.coordinatorName
                    }
                  </Alert>
                </Link>
              ) : (
                ''
              )}

              {mouDetails &&
              getCoordinatorDetails(mouDetails?.coordinatorTwo)
                ?.coordinatorName !== undefined ? (
                <Link
                  to={`/coordinators/${mouDetails?.coordinatorTwo}`}
                  style={{ textDecoration: 'none' }}
                >
                  <Alert className="alert-button-coordinator" color="secondary">
                    {
                      getCoordinatorDetails(mouDetails?.coordinatorTwo)
                        ?.coordinatorName
                    }
                  </Alert>
                </Link>
              ) : (
                ''
              )}

              {mouDetails &&
              getCoordinatorDetails(mouDetails?.coordinatorThree)
                ?.coordinatorName !== undefined ? (
                <Link
                  to={`/coordinators/${mouDetails?.coordinatorThree}`}
                  style={{ textDecoration: 'none' }}
                >
                  <Alert className="alert-button-coordinator" color="secondary">
                    {
                      getCoordinatorDetails(mouDetails?.coordinatorThree)
                        ?.coordinatorName
                    }
                  </Alert>
                </Link>
              ) : (
                ''
              )}

              {mouDetails &&
              getCoordinatorDetails(mouDetails?.coordinatorFour)
                ?.coordinatorName !== undefined ? (
                <Link
                  to={`/coordinators/${mouDetails?.coordinatorFour}`}
                  style={{ textDecoration: 'none' }}
                >
                  <Alert className="alert-button-coordinator" color="secondary">
                    {
                      getCoordinatorDetails(mouDetails?.coordinatorFour)
                        ?.coordinatorName
                    }
                  </Alert>
                </Link>
              ) : (
                ''
              )}

              {mouDetails &&
              getCoordinatorDetails(mouDetails?.coordinatorForeign)
                ?.coordinatorName !== undefined ? (
                <Link
                  to={`/coordinators/${mouDetails?.coordinatorForeign}`}
                  style={{ textDecoration: 'none' }}
                >
                  <Alert className="alert-button-coordinator" color="warning">
                    {
                      getCoordinatorDetails(mouDetails?.coordinatorForeign)
                        ?.coordinatorName
                    }
                  </Alert>
                </Link>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  getApplicationTypeDetails: (applicationTypeId: string) =>
    getApplicationType(state, applicationTypeId),
  getStageDetails: (stageId: string) => getStage(state, stageId),
  getUniversityDetails: (universityCode: string) =>
    getUniversity(state, universityCode),
  getFacultyDetails: (facultyCode: string) => getFaculty(state, facultyCode),
  getCountryDetails: (countryCode: string) => getCountry(state, countryCode),
  getCoordinatorDetails: (coordinatorId: string) =>
    getCoordinator(state, coordinatorId),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCoordinators: () => {
    dispatch(organizationActions.fetchCoordinatorList());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MouViewPage);
