import React, { useEffect, useLayoutEffect, useState } from 'react';
import { IconType } from 'react-icons';
import { AiOutlineHome } from 'react-icons/ai';
import { ImUsers } from 'react-icons/im';
import { BsPersonLinesFill } from 'react-icons/bs';
import { SiGooglescholar } from 'react-icons/si';
import { AiOutlineFlag, AiOutlineApartment } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';
import { HiOutlineDocumentDuplicate } from 'react-icons/hi';

import { DASHBOARD } from '../../../modules/dashboard/routes';
import SideBarItem from '../SideBarItem/SideBarItem';
import { UserRole } from '../../../models/userRole';
import { roleTypes } from '../../../constants/userRole';

import { USERS } from '../../../modules/user/routes';

import './sideBar.css';
import { MOUS } from '../../../modules/mou/routes';
import { COUNTRIES } from '../../../modules/country/routes';
import { COORDINATORS } from '../../../modules/coordinator/routes';
import { FACULTIES } from '../../../modules/faculty/routes';
import { PARTNERS } from '../../../modules/university/routes';

interface SideBarRoute {
  title: string;
  route: string;
  Icon: IconType;
}

const superAdminRoutes: SideBarRoute[] = [
  {
    title: 'Dashboard',
    route: DASHBOARD,
    Icon: AiOutlineHome,
  },
  {
    title: 'Users',
    route: USERS,
    Icon: ImUsers,
  },
  {
    title: 'MoUs',
    route: MOUS,
    Icon: HiOutlineDocumentDuplicate,
  },
];

const adminRoutes: Array<SideBarRoute> = [
  {
    title: 'Dashboard',
    route: DASHBOARD,
    Icon: AiOutlineHome,
  },
  {
    title: 'Users',
    route: USERS,
    Icon: ImUsers,
  },
  {
    title: 'MoUs',
    route: MOUS,
    Icon: HiOutlineDocumentDuplicate,
  },
  {
    title: 'Coordinators',
    route: COORDINATORS,
    Icon: BsPersonLinesFill,
  },
  {
    title: 'Faculties',
    route: FACULTIES,
    Icon: SiGooglescholar,
  },
  {
    title: 'Partners',
    route: PARTNERS,
    Icon: AiOutlineApartment,
  },
  /*{
    title: 'Countries',
    route: COUNTRIES,
    Icon: AiOutlineFlag,
  },*/
];

const editorRoutes: SideBarRoute[] = [
  {
    title: 'Dashboard',
    route: DASHBOARD,
    Icon: AiOutlineHome,
  },
  {
    title: 'MoUs',
    route: MOUS,
    Icon: HiOutlineDocumentDuplicate,
  },
];

const viewerRoute: SideBarRoute[] = [
  {
    title: 'Dashboard',
    route: DASHBOARD,
    Icon: AiOutlineHome,
  },
  {
    title: 'MoUs',
    route: MOUS,
    Icon: HiOutlineDocumentDuplicate,
  },
];

interface SideBarProps {
  userRole: UserRole | null;
}

const SideBar: React.FC<SideBarProps> = ({
  userRole,
}: SideBarProps): JSX.Element => {
  // const [isCollapsed, setCollapsed] = useState(true);

  let routes: Array<SideBarRoute> = [];
  if (userRole) {
    switch (userRole.roleName) {
      case roleTypes.ADMIN:
        routes = [...adminRoutes];
        break;
      case roleTypes.SUPER_ADMIN:
        routes = [...superAdminRoutes];
        break;
      case roleTypes.EDITOR:
        routes = [...editorRoutes];
        break;
      case roleTypes.VIEWERS:
        routes = [...viewerRoute];
        break;
      default:
        routes = [];
    }
  }
  const location = useLocation();

  return (
    <div className="main">
      <div id="side-bar-left" className="sidebar">
        <ul>
          {routes.map((route) => (
            <SideBarItem
              key={route.route}
              route={route}
              currentPath={location.pathname}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
