import CoordinatorListPage from '../pages/CoordinatorListPage';
import CoordinatorViewPage from '../pages/CoordinatorViewPage';

export const COORDINATORS = '/coordinators';
export const COORDINATOR = `${COORDINATORS}/:coordinatorId`;

export default [
  {
    path: COORDINATORS,
    component: CoordinatorListPage,
    authenticated: true,
    permissions: [],
    exact: true,
  },
  {
    path: COORDINATOR,
    component: CoordinatorViewPage,
    authenticated: true,
    permissions: [],
    exact: true,
  },
];
