import { Observable } from 'rxjs';

import { DELETE, GET, POST, PUT } from './baseApi';
import { Mou, MousRequest, MousResponse } from '../../models/mou';

const MOU_ENDPOINT = '/mou/';

export const getMous = (payload?: MousRequest): Observable<MousResponse> =>
  GET(MOU_ENDPOINT, { ...payload });

export const getMou = (mouId: string): Observable<Mou> =>
  GET(`${MOU_ENDPOINT}${mouId}`);
