import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { connect, useSelector } from 'react-redux';
import { HiFilter } from 'react-icons/hi';

import { Alert } from 'reactstrap';

import { AppModal } from '../../../../shared/components';
import DateRangePickerComp from '../../../../shared/components/DateRangePicker/DateRangePickerComp';
import { RootState } from '../../../../app/redux/rootReducer';
import SearchDropDown from '../../../../shared/components/SearchDropDown/SearchDropDown';
import {
  getCountryList,
  getStageList,
} from '../../../dashboard/redux/organizationSelector';
import { Stage, StageState } from '../../../../models/stage';
import { organizationActions } from '../../../dashboard/redux/organizationSlice';
import { Country, CountryState } from '../../../../models/country';
import { renderStage } from '../../../../utils/stageGetFunction';

export interface FilterValues {
  stageId: string;
  countryCode: string;
}

interface SearchProps {
  state: RootState;
  filterValueObj: FilterValues;
  selectedFilter?: (value: FilterValues | any) => void;
  stages: Array<Stage>;
  countries: Array<Country>;
  fetchStages: () => void;
  fetchCountries: () => void;
}

interface DropDownElement {
  value: string;
  label: string;
}

let tmpFilterValueList: FilterValues;

const SearchBox: React.FC<SearchProps> = ({
  state,
  filterValueObj,
  selectedFilter,
  stages,
  countries,
  fetchStages,
  fetchCountries,
}: SearchProps) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  //stage
  const [isStageSelected, setIsStageSelected] = useState(false);
  const [selectedStageFilter, setStageFilter] = useState<DropDownElement>();
  const [stageList, setStageList] = useState<Array<DropDownElement>>([]);

  const stageStoreList = useSelector(
    (state: RootState): StageState => state.organization.stage
  );

  //country
  const [isCountrySelected, setIsCountrySelected] = useState(false);
  const [selectedCountryFilter, setCountryFilter] = useState<DropDownElement>();
  const [countryList, setCountryList] = useState<Array<DropDownElement>>([]);

  const countryStoreList = useSelector(
    (state: RootState): CountryState => state.organization.country
  );

  const [filterValueList, setFilterValueList] = useState<FilterValues>(
    filterValueObj
  );

  function handleModalOpen() {
    setShowModal(!showModal);
  }

  function handleModalClose() {
    setShowModal(!showModal);
  }

  function handleApplyFilterClick() {
    setShowModal(!showModal);
    selectedFilter && selectedFilter(filterValueList);
  }

  //stage
  function handleStageTagClose() {
    tmpFilterValueList = {
      ...filterValueList,
      stageId: '',
    };
    setFilterValueList(tmpFilterValueList);
    selectedFilter && selectedFilter(tmpFilterValueList);
    setIsStageSelected(false);
  }

  function handleStageChange(option: DropDownElement | null) {
    option &&
      setFilterValueList({
        ...filterValueList,
        stageId: option.value,
      });
    option && setStageFilter(option);
    setIsStageSelected(true);
  }

  //country
  function handleCountryTagClose() {
    tmpFilterValueList = {
      ...filterValueList,
      countryCode: '',
    };
    setFilterValueList(tmpFilterValueList);
    selectedFilter && selectedFilter(tmpFilterValueList);
    setIsCountrySelected(false);
  }

  function handleCountryChange(option: DropDownElement | null) {
    option &&
      setFilterValueList({
        ...filterValueList,
        countryCode: option.value,
      });
    option && setCountryFilter(option);
    setIsCountrySelected(true);
  }

  useEffect(() => {
    setIsLoading(true);

    // Stages
    if (stageStoreList.list) {
      stages.map((item) => {
        stageList.push({
          value: item.stageId,
          label: item.stageName,
        });
      });
      setStageList(stageList);

      setIsLoading(false);

      const tempStageObj = stageList.filter(
        (option) => option.value === filterValueList.stageId
      );

      if (tempStageObj.length > 0) {
        setStageFilter(tempStageObj[0]);
        setIsStageSelected(true);
      }
    } else {
      fetchStages();
    }

    // country
    if (countryStoreList.list) {
      countries.map((item) => {
        countryList.push({
          value: item.countryCode,
          label: item.countryName,
        });
      });
      setCountryList(countryList);

      setIsLoading(false);

      const tempCountryObj = countryList.filter(
        (option) => option.value === filterValueList.countryCode
      );

      if (tempCountryObj.length > 0) {
        setCountryFilter(tempCountryObj[0]);
        setIsCountrySelected(true);
      }
    } else {
      fetchCountries();
    }
  }, [stageStoreList.loading, countryStoreList.loading]);

  return (
    <div className="container">
      <div className="row pt-2">
        <Alert
          className="mr-2"
          color="info"
          isOpen={filterValueList.stageId === '' ? false : true}
          toggle={() => handleStageTagClose()}
        >
          {renderStage(selectedStageFilter?.label)}
        </Alert>

        <Alert
          className="mr-2"
          color="danger"
          isOpen={filterValueList.countryCode === '' ? false : true}
          toggle={() => handleCountryTagClose()}
        >
          <img
            src={
              selectedCountryFilter?.value &&
              `/images/country_flags/${selectedCountryFilter?.value}.svg`
            }
            alt={selectedCountryFilter?.value}
            className="country-image-mou"
          />{' '}
          {selectedCountryFilter?.label}
        </Alert>

        <div className="col">
          <a
            onClick={() => handleModalOpen()}
            className="add-new-link-btn ft-size-16 float-right margin-top-none"
          >
            <HiFilter size={18} /> Filter
          </a>
        </div>
      </div>
      <AppModal
        onClose={() => handleModalClose()}
        show={showModal}
        title="Add your filter list"
      >
        <div className="container">
          {!isLoading && !error && (
            <div className="row mb-2">
              <div className="col">
                <SearchDropDown
                  dataList={stageList}
                  selectedValueElement={filterValueList.stageId}
                  placeholder="Enter Stage Name"
                  nameTop="Stage Name"
                  selectedObjectChanged={(value) => handleStageChange(value)}
                />
              </div>
            </div>
          )}
          {!isLoading && !error && (
            <div className="row mb-2">
              <div className="col">
                <SearchDropDown
                  dataList={countryList}
                  selectedValueElement={filterValueList.countryCode}
                  placeholder="Enter Country Name"
                  nameTop="Country Name"
                  selectedObjectChanged={(value) => handleCountryChange(value)}
                />
              </div>
            </div>
          )}
          <div className="row pt-3">
            <div className="col d-flex justify-content-center">
              <a
                onClick={() => handleApplyFilterClick()}
                className="add-new-link-btn ft-size-16"
              >
                <HiFilter size={18} /> APPLY FILTER
              </a>
            </div>
          </div>
        </div>
      </AppModal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  state,
  stages: getStageList(state),
  countries: getCountryList(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchStages: () => {
    dispatch(organizationActions.fetchStageList());
  },
  fetchCountries: () => {
    dispatch(organizationActions.fetchCountryList());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);
