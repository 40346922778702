import AdminUserDetails from '../../dashboard/container/Admin/AdminUserDetails';
import MouPage from '../pages/MouListPage';
import MouViewPage from '../pages/MouView/MouViewPage';
// import UserViewPage from '../page/UserView';

export const MOUS = '/mous';
export const MOU = `${MOUS}/:mouId`;

export default [
  {
    path: MOUS,
    component: MouPage,
    authenticated: true,
    permissions: [],
    exact: true,
  },
  {
    path: MOU,
    component: MouViewPage,
    authenticated: true,
    permissions: [],
    exact: true,
  },
];
