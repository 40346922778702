/* eslint-disable import/no-unresolved */
import React from 'react';

import { HiStatusOnline } from 'react-icons/hi';
import { AiOutlineApartment } from 'react-icons/ai';
import { SiGooglescholar } from 'react-icons/si';
import { BsPersonLinesFill } from 'react-icons/bs';
import { Badge } from 'reactstrap';

import './HomeTopDashboard.css';
import { Link } from 'react-router-dom';
import { COORDINATORS } from '../../../coordinator/routes';
import { FACULTIES } from '../../../faculty/routes';
import { PARTNERS } from '../../../university/routes';

interface HomeDashboardProps {
  expiredMou?: string;
  numCoordinators?: string;
  numFaculties?: string;
  numPartners?: string;
}

const HomeTopDashboard: React.FC<HomeDashboardProps> = ({
  expiredMou,
  numCoordinators,
  numFaculties,
  numPartners,
}) => (
  <div className="row home-top-dashboard">
    <div className="col-lg-3 col-md-6">
      <Link to={''} style={{ textDecoration: 'none' }}>
        <div className="card-mou dashboard-mou">
          <span>Expired</span>
          <h2 className="dashboard-ongoing-mou">{expiredMou}</h2>
          <Badge color="danger">Renew</Badge> MoUs
          <div className="side-box">
            <HiStatusOnline className="text-delayed-color" />
          </div>
        </div>
      </Link>
    </div>
    <div className="col-lg-3 col-md-6">
      <Link to={COORDINATORS} style={{ textDecoration: 'none' }}>
        <div className="card-mou dashboard-mou">
          <span>Coordinators</span>
          <h2 className="dashboard-ongoing-mou">{numCoordinators}</h2>
          <Badge color="info">Manage</Badge> MoUs
          <div className="side-box">
            <BsPersonLinesFill className="text-ongoing-color" />
          </div>
        </div>
      </Link>
    </div>
    <div className="col-lg-3 col-md-6">
      <Link to={FACULTIES} style={{ textDecoration: 'none' }}>
        <div className="card-mou dashboard-mou">
          <span>Faculties</span>
          <h2 className="dashboard-ongoing-mou">{numFaculties}</h2>
          <Badge color="success">Handle</Badge> MoUs
          <div className="side-box">
            <SiGooglescholar className="text-completed-color" />
          </div>
        </div>
      </Link>
    </div>
    <div className="col-lg-3 col-md-6">
      <Link to={PARTNERS} style={{ textDecoration: 'none' }}>
        <div className="card-mou dashboard-mou">
          <span>Partners</span>
          <h2 className="dashboard-ongoing-mou">{numPartners}</h2>
          <Badge color="warning">Active</Badge> Partners
          <div className="side-box">
            <AiOutlineApartment className="text-users-color" />
          </div>
        </div>
      </Link>
    </div>
  </div>
);

export default HomeTopDashboard;
