import * as React from 'react';
import { Modal } from 'react-bootstrap';

import './ModalView.css';

export interface UpdateModalProps {
  onClose: () => void;
  show: boolean;
  title: string;
  size?: 'sm' | 'lg' | 'xl';
}

const UpdateModal: React.FC<UpdateModalProps> = (props) => {
  const { title, show, onClose, children, size } = props;
  return (
    <>
      <Modal
        show={show}
        size={size}
        onHide={onClose}
        backdrop="static"
        keyboard={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  );
};

UpdateModal.defaultProps = {};

export default UpdateModal;
