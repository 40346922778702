import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Link, withRouter } from 'react-router-dom';

import { AppButton, AppInput } from '../../../shared/forms/components';
import { authActions } from '../redux/authSlice';
import { getInitialValues, getValidationSchema } from '../forms/login';

import './login.css';
import AddPartnerForm from '../../university/form/AddPartnerForm';
import { AppModal } from '../../../shared/components';
import { FaSignInAlt } from 'react-icons/fa';

const Login: React.FC<Props> = (props) => {
  const [authLoading, setAuthLoading] = useState(false);
  const [authError, setAuthError] = useState('');
  const history = useHistory();

  const handleOnLogin = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    setAuthLoading(true);
    setAuthError('');
    props.login({ email, password });
  };

  const navigateToForgotPassword = () => {
    history.push('/reset-password');
  };

  useEffect(() => {
    document.title = 'Mou | Login';
  }, []);

  return (
    <>
      <div>
        <nav className="header-nav-bar">
          <div className="header-logo">
            <Link to="/">
              <img
                className="site-logo"
                src="/images/logo/University_of_Peradeniya_crest-1.png"
                alt="logo"
              />
            </Link>
          </div>
        </nav>
      </div>
      <div className="back_image">
        <div className="row justify-content-md-center">
          <div className="table_outer_div_dis mt-5">
            <div className="container ">
              <h1 className="font_head p-2 header-txt">
                International Relations Office-MoU Dashboard
              </h1>
              <p className="font_normal">
                With the concept of internationalization of University of
                Peradeniya, the International Relations Office is in its journey
                of enhancing global Relations, promotes multidisciplinary
                collaborative research programs, and facilitating students and
                staff members with resources and providing platforms to
                scholarly exchanges.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="table_outer_div_blue my-5">
            <div className="container">
              <div className="row justify-content-md-center">
                <div className="col">
                  <div className="row justify-content-md-center">
                    <div className="col">
                      <Formik
                        initialValues={getInitialValues()}
                        onSubmit={handleOnLogin}
                        validationSchema={getValidationSchema()}
                      >
                        {() => (
                          <Form className="p-1 ">
                            <div className="d-flex justify-content-center mb-5 text-center" />
                            <h2 className="title-tag-style  text-center mb-4">
                              LOG IN
                            </h2>
                            <AppInput
                              label="Email"
                              loading={authLoading}
                              name="email"
                              placeholder="Enter Email"
                              type="email"
                            />
                            <AppInput
                              label="Password"
                              loading={authLoading}
                              name="password"
                              placeholder="Enter Password"
                              type="password"
                            />
                            <AppButton
                              loading={authLoading}
                              type="submit"
                              text="Login"
                            />
                            {authError && (
                              <span className="d-block text-danger text-center mt-2">
                                {authError}
                              </span>
                            )}
                            <div className="text-center my-3 ">
                              <p className="normal-p-tag-style">
                                <AppButton
                                  text="Forgot password?"
                                  link
                                  onClick={navigateToForgotPassword}
                                />
                              </p>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = () => ({});

const dispatchProps = {
  login: authActions.login,
};
type DispatchProps = typeof dispatchProps;

type Props = DispatchProps;
export default withRouter(connect(mapStateToProps, dispatchProps)(Login));
