import { Observable } from 'rxjs';

import { GET, POST, PUT } from './baseApi';
import { StageCount } from '../../models/stage';
import { OverViewAdmin } from '../../models/overView';

const STAGE_COUNT_ENDPOINT = '/mouStage/stageCountOverall/';
const OVERVIEW_ADMIN_COUNT_ENDPOINT = '/overview/';

export const getStagesCount = (): Observable<Array<StageCount>> =>
  GET(STAGE_COUNT_ENDPOINT);

export const getAdminOverallCount = (): Observable<OverViewAdmin> =>
  GET(OVERVIEW_ADMIN_COUNT_ENDPOINT);
