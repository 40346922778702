import { Observable } from 'rxjs';

import { GET, POST, PUT } from './baseApi';
import { Coordinator } from '../../models/coordinator';

const COORDINATOR_ENDPOINT = '/coordinator/';

export const getCoordinators = (): Observable<Array<Coordinator>> =>
  GET(COORDINATOR_ENDPOINT);

export const getCoordinator = (
  coordinatorId: string
): Observable<Coordinator> => GET(`${COORDINATOR_ENDPOINT}${coordinatorId}`);

export const createCoordinator = (
  coordinator: Coordinator
): Observable<Coordinator> => POST(COORDINATOR_ENDPOINT, { ...coordinator });

// export const updateCoordinator = (
//   coordinator: Coordinator
// ): Observable<Coordinator> =>
//   PUT(`${COORDINATOR_ENDPOINT}${coordinator.coordinatorId}`, {
//     ...coordinator,
//   });
