import React from 'react';
import { ChartShallowDataShape, PieArcSeries, PieChart } from 'reaviz';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  LineChart,
  Line,
  Brush,
  Label,
} from 'recharts';

import { chartTypes } from '../../../constants/chart';

import './AppChart.scss';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  chartType: string;
  width?: number;
  height?: number;
  compare?: boolean;
  colorScheme?: Array<string>;
  legend?: boolean;
  innerText?: string;
  label?: string;
  tooltip?: boolean;
  dataKey: Array<string>;
}

const AppChart: React.FC<Props> = ({
  chartType,
  width = 500,
  height = 300,
  data,
  innerText,
  label,
  compare = false,
  tooltip = true,
  legend = true,
  dataKey,
}: Props) => {
  const getChart = () => {
    switch (chartType) {
      case chartTypes.LINE:
        return (
          <LineChart width={width} height={height} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={dataKey[0]} padding={{ left: 30, right: 30 }} />
            <YAxis padding={{ top: 10 }} />
            {tooltip && <Tooltip />}
            {legend && compare && <Legend />}
            <Line
              type="monotone"
              dataKey={dataKey[1]}
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
            {compare && dataKey.length >= 2 && (
              <Line type="monotone" dataKey={dataKey[2]} stroke="#82ca9d" />
            )}
          </LineChart>
        );
      case chartTypes.BAR:
        return (
          <BarChart
            width={width}
            height={height}
            data={data}
            barSize={45}
            margin={{
              top: 5,
              right: 10,
              left: 10,
              bottom: 5,
            }}
          >
            <XAxis
              dataKey={dataKey[0]}
              scale="point"
              padding={{ left: 25, right: 10 }}
            >
              <Label value="Stages" position="insideBottom" />
            </XAxis>
            <YAxis padding={{ top: 10 }} orientation="left">
              <Label value="Number of MoUs" position="insideLeft" angle={-90} />
            </YAxis>
            <Tooltip />
            <CartesianGrid strokeDasharray="3 3" />
            <Bar
              dataKey={dataKey[1]}
              fill="#d38a12"
              background={{ fill: '#eee' }}
            />
          </BarChart>
        );
      case chartTypes.PIE:
        return (
          <div className="pie-chart-container">
            <div className="pie-chart-outer">
              <PieChart
                width={350}
                height={250}
                data={data as ChartShallowDataShape[]}
                series={
                  <PieArcSeries
                    doughnut={true}
                    colorScheme={['#fa8072', '#8884d8', '#82ca9d']}
                    cornerRadius={4}
                    padAngle={0.02}
                    padRadius={200}
                  />
                }
              />
            </div>
            {innerText && <h2 className="pie-chart-inner">{innerText}</h2>}
          </div>
        );
      case chartTypes.POSITIVE_NEGATIVE:
        return (
          <BarChart
            width={width}
            height={height}
            data={data}
            stackOffset="sign"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={dataKey[0]} />
            <YAxis padding={{ top: 10, bottom: 10 }} />
            {tooltip && <Tooltip />}
            {legend && <Legend />}
            <ReferenceLine y={0} stroke="#000" />
            <Bar dataKey={dataKey[1]} fill="#8884d8" stackId="stack" />
            <Bar dataKey={dataKey[2]} fill="#82ca9d" stackId="stack" />
            <Bar dataKey={dataKey[3]} fill="#ffc658" stackId="stack" />
          </BarChart>
        );
      case chartTypes.BRUSH_BAR:
        return (
          <BarChart
            width={width}
            height={height}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={dataKey[0]} />
            <YAxis />
            <Tooltip />
            <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
            <ReferenceLine y={0} stroke="#000" />
            <Brush dataKey={dataKey[0]} height={30} stroke="#8884d8" />
            <Bar dataKey={dataKey[1]} stackId="a" fill="#8884d8" />
            <Bar dataKey={dataKey[2]} stackId="a" fill="#82ca9d" />
          </BarChart>
        );
    }
  };

  return (
    <div className="chart-container table_outer_div">
      <div className="container">
        <div className="row align-items-start pb-3">
          <div className="col header-txt-camelcase">
            {label && <h5>{label}</h5>}
          </div>
        </div>
      </div>
      {data.length > 0 ? (
        width && height ? (
          getChart()
        ) : (
          <div style={{ width: '50vw', height: '50vh' }}>{getChart()}</div>
        )
      ) : (
        <div>No data</div>
      )}
    </div>
  );
};

export default AppChart;
