import React, { useEffect } from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import storage from '../../services/storage/localStorage';
import storageConstants from '../../constants/storage';
import AppRoutes, { NOT_FOUND, MouRoute } from '../routes';
import {
  Loader,
  ProtectedRoute,
  UnauthenticatedRoute,
} from '../../shared/components';
import SideBar from '../components/SideBar/SideBar';
import { RootState } from '../redux/rootReducer';
import { getAuth, getUserRole } from '../../modules/auth/redux/authSelector';
import { AuthenticationResponse } from '../../models/auth';
import TopBar from '../components/TopBar/TopBar';
import { authActions } from '../../modules/auth/redux/authSlice';

import GlobalNavigation from '../components/navigation/GlobalNavigation';

import styles from './App.module.scss';
import { appAction } from '../redux/appSlice';
import GlobalToast from '../components/GlobalToast';
import { UserRole } from '../../models/userRole';
import { getAppLoading } from '../redux/appSelector';

interface AppContainerProps {
  auth: AuthenticationResponse | null;
  loading: boolean;
  userRole: UserRole | null;
  // eslint-disable-next-line no-unused-vars
  setAuthentication: (payload: AuthenticationResponse) => void;
  loadingSuccess: () => void;
}

const AppContainer: React.FC<AppContainerProps> = ({
  auth,
  loading,
  userRole,
  setAuthentication,
  loadingSuccess,
}: AppContainerProps) => {
  useEffect(() => {
    //  eslint-disable-next-line
    const auth: AuthenticationResponse | null = storage.get(
      storageConstants.AUTH_RESPONSE
    );

    if (auth) {
      setAuthentication(auth);
    }
    loadingSuccess();
  }, []);

  const renderContainer = () => (
    <BrowserRouter>
      <GlobalNavigation />
      <GlobalToast />
      <div className={styles.main_root}>
        <div className="container-fluid pl-0 pr-0">{auth && <TopBar />}</div>
        <div className={styles.subRoot}>
          {auth && <SideBar userRole={userRole} />}
          <div className={styles.route__container}>
            <Switch>
              {AppRoutes(userRole).map(
                ({
                  authenticated,
                  exact,
                  component: Component,
                  path,
                  common,
                }: MouRoute) =>
                  common ? (
                    <Route
                      key={path}
                      path={path}
                      exact={exact}
                      component={Component}
                    />
                  ) : (
                    <Route key={path} exact={exact} path={path}>
                      {authenticated ? (
                        <ProtectedRoute component={Component} />
                      ) : (
                        <UnauthenticatedRoute component={Component} />
                      )}
                    </Route>
                  )
              )}
              {/* TODO: Cant give sub url directly */}
              {/* <Redirect from="*" to={NOT_FOUND} /> */}
            </Switch>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );

  return loading ? <Loader /> : <>{renderContainer()}</>;
};

const mapStateToProps = (state: RootState) => ({
  auth: getAuth(state),
  loading: getAppLoading(state),
  userRole: getUserRole(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setAuthentication: (payload: AuthenticationResponse) => {
    dispatch(authActions.authenticateUserSuccess(payload));
  },
  loadingSuccess: () => {
    dispatch(appAction.loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
