import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import { AppButton } from '../../../shared/forms/components';

import ResetPasswordApplyForm from '../forms/ResetPasswordApplyForm';

import './login.css';

const ResetPasswordApply: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    document.title = 'Mou | Reset Password';
  }, []);

  const navigateToForgotPassword = () => {
    history.push('/login');
  };

  return (
    <div>
      {/* <TopBar /> */}
      <div className="container container-width-set-none">
        <div className="row justify-content-center">
          <div className="col-sm-6 margin-top-set-5vh">
            <div className="row justify-content-md-center">
              <div className="col-sm-8">
                <ResetPasswordApplyForm />
                <div className="text-center mt-3">
                  <p className="normal-p-tag-style">
                    Back to
                    <AppButton
                      text="login"
                      link
                      onClick={navigateToForgotPassword}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResetPasswordApply;
