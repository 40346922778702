import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaUniversity } from 'react-icons/fa';
import { MdViewHeadline } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { RootState } from '../../../app/redux/rootReducer';
import { AppModal, AppTable } from '../../../shared/components';
import { getUniversities } from '../../dashboard/redux/organizationSelector';
import AddPartnerForm from '../form/AddPartnerForm';
import { Dispatch } from 'redux';
import { organizationActions } from '../../dashboard/redux/organizationSlice';
import { connect } from 'react-redux';
import { University } from '../../../models/university';

interface UniversityListProps {
  universities: Array<University>;
  fetchUniversities: () => void;
}

const PartnerListPage: React.FC<UniversityListProps> = ({
  universities,
  fetchUniversities,
}: UniversityListProps) => {
  const [showModal, setShowModal] = useState(false);
  const [refreshState, setRefreshState] = useState(false);
  const dispatch = useDispatch();

  const handleModalToggle = () => {
    setShowModal(!showModal);
  };

  const getPartnerTableData = () => {
    const array: Array<JSX.Element> = [];

    {
      universities.map((item, i = 1) =>
        array.push(
          <tr>
            <th scope="row">{i + 1}</th>
            <td>{item.universityName}</td>
            <td>
              <img
                src={`/images/country_flags/${item.countryCode}.svg`}
                alt={item.countryCode}
                className="country-image-mou"
              />
            </td>
            <td>
              <a href={`tel:${item.contactNo}`}>{item.contactNo}</a>
            </td>
            <td>{item.contactEmail}</td>
            {/*<td>
              <Link
                to={'partners/'.concat(item.universityCode)}
                className="add-new-link-btn-thin-border ft-size-16"
              >
                <MdViewHeadline size={20} />
              </Link>
            </td>*/}
          </tr>
        )
      );
    }

    return array;
  };

  useEffect(() => {
    fetchUniversities();
  }, []);

  const handleOnSuccessCreateUniversity = () => {
    handleModalToggle();
    setRefreshState(!refreshState);
    fetchUniversities();
  };

  return (
    <>
      <div className="container pt-3 main-container-div">
        <div>
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-end">
              <div>
                <a
                  onClick={handleModalToggle}
                  className="add-new-link-btn ft-size-16"
                >
                  <FaUniversity size={18} /> Add Partner Institute
                </a>
                <AppModal
                  onClose={handleModalToggle}
                  show={showModal}
                  title="Add Partner Institute"
                >
                  <AddPartnerForm
                    createUniversitySuccessCallBack={
                      handleOnSuccessCreateUniversity
                    }
                  />
                </AppModal>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="table_outer_div">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 header-txt">
                    <h5>PARTNER INSTITUTE LIST</h5>
                  </div>
                </div>
              </div>

              <AppTable
                head={[
                  '',
                  'Partner Institute Name',
                  '',
                  'Phone Number',
                  'Email',
                ]}
                data={getPartnerTableData()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  universities: getUniversities(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchUniversities: () => {
    dispatch(organizationActions.fetchUniversityList());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnerListPage);
