import React, { useEffect } from 'react';
import { IconType } from 'react-icons';
import { AiOutlineHome } from 'react-icons/ai';
import { Link } from 'react-router-dom';

import './SideBarItem.css';

interface SideBarItemProps {
  route: { title: string; route: string; Icon: IconType };
  currentPath: string;
}

const SideBarItem = ({
  route: { title, route, Icon },
  currentPath,
}: SideBarItemProps): JSX.Element => (
  <li>
    <Link
      className={
        route.split('/')[1] === currentPath.split('/')[1]
          ? 'active-sidebar-item'
          : ''
      }
      to={route}
    >
      <Icon size={20} />
      <span className="sidebar-item-text">{title}</span>
    </Link>
  </li>
);

export default SideBarItem;
