import React from 'react';
import { roleTypes } from '../../constants/userRole';
import { UserRole } from '../../models/userRole';

import AuthRoutes from '../../modules/auth/routes';
import DashboardRoutes from '../../modules/dashboard/routes';
import NotFoundPage from '../pages/NotFoundPage';
import UserRoutes from '../../modules/user/routes';
import MouRoutes from '../../modules/mou/routes';
import CoordinatorRoutes from '../../modules/coordinator/routes';
import FacultyRoutes from '../../modules/faculty/routes';
import PartnerRoutes from '../../modules/university/routes';
import CountryRoutes from '../../modules/country/routes';

export interface MouRoute {
  path: string;
  component: React.ComponentType;
  authenticated?: boolean;
  permissions?: string[];
  exact?: boolean;
  common?: boolean;
}

export const NOT_FOUND = '404-not-found';

const AppRoutes: MouRoute[] = [
  {
    path: NOT_FOUND,
    component: NotFoundPage,
    authenticated: false,
    permissions: [],
    exact: false,
    common: true,
  },
];

const getRoutes = (userRole: UserRole | null): Array<MouRoute> => {
  const commonRoutes = [
    ...AuthRoutes,
    ...AppRoutes,
    ...DashboardRoutes,
    ...UserRoutes,
    ...MouRoutes,
    ...CoordinatorRoutes,
    ...FacultyRoutes,
    ...PartnerRoutes,
    ...CountryRoutes,
  ];
  if (userRole) {
    switch (userRole.roleName) {
      case roleTypes.SUPER_ADMIN:
        return [...UserRoutes, ...DashboardRoutes, ...commonRoutes];
      case roleTypes.ADMIN:
        return [...commonRoutes];
      case roleTypes.EDITOR:
        return [...commonRoutes];
      case roleTypes.VIEWERS:
        return [...commonRoutes];
      default:
        return commonRoutes;
    }
  }
  return commonRoutes;
};

export default getRoutes;
