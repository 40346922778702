/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { v4 as uuidv4 } from 'uuid';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const generateUuid = () => uuidv4();

const delayAsync = async (timeout: number): Promise<void> => {
  await new Promise((resolve) => setTimeout(resolve, timeout));
};

export default {
  delayAsync,
  generateUuid,
};
