import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'reactstrap';

import '../ModalView/ModalView.css';

export interface Props {
  onClose: () => void;
  onClickOk: () => void;
  show: boolean;
  title?: string;
  description?: string;
  size?: 'sm' | 'lg' | 'xl';
}

const WarningModal: React.FC<Props> = (props) => {
  const { title, show, onClose, onClickOk, description, size } = props;
  return (
    <>
      <Modal
        show={show}
        size={size}
        onHide={onClose}
        backdrop="static"
        keyboard={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{description}</Modal.Body>
        <Modal.Footer>
          <Button color="danger" onClick={() => onClickOk()}>
            OK
          </Button>
          <Button color="secondary" onClick={() => onClose()}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

WarningModal.defaultProps = {
  title: 'Warning',
  description: 'Are you sure you want to permanently remove this item?',
};

export default WarningModal;
