import { Observable } from 'rxjs';

import { GET, POST, PUT } from './baseApi';
import { Country } from '../../models/country';

const COUNTRY_ENDPOINT = '/country/';

export const getCountries = (): Observable<Array<Country>> =>
  GET(COUNTRY_ENDPOINT);

export const getCountry = (countryCode: string): Observable<Country> =>
  GET(`${COUNTRY_ENDPOINT}${countryCode}`);

export const createCountry = (country: Country): Observable<Country> =>
  POST(COUNTRY_ENDPOINT, { ...country });

export const updateCountry = (country: Country): Observable<Country> =>
  PUT(`${COUNTRY_ENDPOINT}${country.countryCode}`, {
    ...country,
  });
