import React, { useState, useEffect } from 'react';
import {
  AiFillFlag,
  AiOutlineFlag,
  AiOutlineFolderAdd,
  AiOutlineHome,
  AiOutlineUserAdd,
} from 'react-icons/ai';
import { FaUniversity } from 'react-icons/fa';
import { AppModal, AppTable } from '../../../shared/components';
import AddCoordinatorForm from '../../coordinator/form/AddCoordinatorForm';
import AddFacultyForm from '../../faculty/form/AddFacultyForm';
import AddPartnerForm from '../../university/form/AddPartnerForm';
import AddCountryForm from '../forms/AddCountryForm';

const CountryPage: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const handleModalToggle = () => {
    setShowModal(!showModal);
  };
  const getCountryTableData = () => {
    const array: Array<JSX.Element> = [];
    return array;
  };
  return (
    <>
      <div className="container pt-1 main-container-div">
        <div className="row">
          <div className="col-lg-12 d-flex justify-content-end">
            <a
              onClick={handleModalToggle}
              className="add-new-link-btn ft-size-16"
            >
              <AiOutlineFlag size={18} /> Add Country
            </a>
            <AppModal
              onClose={handleModalToggle}
              show={showModal}
              title="Add Country"
            >
              <AddCountryForm />
            </AppModal>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="table_outer_div">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 header-txt">
                    <h5>COUNTRY LIST</h5>
                  </div>
                </div>
              </div>

              <AppTable
                head={['Country Name', 'Flag', 'Country Code']}
                data={getCountryTableData()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CountryPage;
