import React, { useState, useEffect } from 'react';
import HomeTopDashboard from './HomeTopDashboard/HomeTopDashboard';
import { useDispatch, useSelector, connect } from 'react-redux';

import AppChart from '../../../shared/components/AppChart/AppChart';
import { chartTypes } from '../../../constants/chart';
import {
  getStagesCount,
  getAdminOverallCount,
} from '../../../services/api/overallApi';
import { Stage, StageCount } from '../../../models/stage';
import { RootState } from '../../../app/redux/rootReducer';
import { getStage } from '../redux/organizationSelector';
import { OverViewAdmin } from '../../../models/overView';

interface dataObject {
  type?: string;
  count: string;
}

interface Props {
  getStageDetails: (stageId: string) => Stage | undefined;
}

const AdminDashboard: React.FC<Props> = ({ getStageDetails }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [stagesCountDetails, setStagesCountDetails] = useState<
    Array<dataObject>
  >();
  const [
    overViewCountDetails,
    setOverViewCountDetails,
  ] = useState<OverViewAdmin>();

  useEffect(() => {
    setIsLoading(true);

    getStagesCount().subscribe(
      (response: Array<StageCount>) => {
        setIsLoading(false);

        const array: Array<dataObject> = [];
        response.map((key) =>
          array.push({
            type: getStageDetails(key.stageId)?.stageName,
            count: key.stageCount,
          })
        );

        setStagesCountDetails(array);
      },
      (error: Error) => {
        setIsLoading(true);
      }
    );

    getAdminOverallCount().subscribe(
      (response: OverViewAdmin) => {
        setIsLoading(false);

        setOverViewCountDetails(response);
      },
      (error: Error) => {
        setIsLoading(true);
      }
    );
  }, [stagesCountDetails?.length]);

  return (
    <div className="container pt-1 main-container-div">
      <div className="row pt-3 pl-1">
        <h5 className="title-h5-style">Statistics</h5>
      </div>
      <div className="row pt-3">
        <div className="col">
          <HomeTopDashboard
            expiredMou={overViewCountDetails?.expiredMous}
            numCoordinators={overViewCountDetails?.numOfCoordinators}
            numFaculties={overViewCountDetails?.numOfFaculties}
            numPartners={overViewCountDetails?.numOfPartners}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          {!isLoading && stagesCountDetails && (
            <AppChart
              label="SUMMARY OF ONGOING MoUs"
              height={400}
              width={1000}
              chartType={chartTypes.BAR}
              data={stagesCountDetails}
              dataKey={['type', 'count']}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  getStageDetails: (stageId: string) => getStage(state, stageId),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
