import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { connect, useDispatch } from 'react-redux';

import {
  AppButton,
  AppInput,
  AppDropdown,
} from '../../../shared/forms/components';
import { CreateUniversityForm, University } from '../../../models/university';
//import {NewAuth } from '../../../models/auth';
import { notificationActions } from '../../../app/redux/notification.slice';
import { useHistory } from 'react-router';
import { AxiosError } from 'axios';

import miscUtils from '../../../utils/miscUtils';
import { createFaculty } from '../../../services/api/facultyApi';
import { createUniversity } from '../../../services/api/universityApi';
import { getCountryList } from '../../dashboard/redux/organizationSelector';
import { RootState } from '../../../app/redux/rootReducer';
import { Country } from '../../../models/country';

const getInitialValues = () => ({
  universityName: '',
  contactNo: '',
  contactEmail: '',
  contactInfo: '',
  countryCode: '', //should be a dropdown
});

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object().shape({
  universityName: Yup.string()
    .max(30, 'maximum of 50 letters')
    .required('This is a required field'),
  contactEmail: Yup.string()
    .email('Should be a valid Email')
    .required('This is a required field'),
  contactNo: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('This is a required field'),
  contactInfo: Yup.string().max(30, 'maximum of 100 letters'),
  countryCode: Yup.string().required('This is a required field'),
});

interface Props {
  countries: Array<Country>;
  createUniversitySuccessCallBack?: () => void;
}

const AddPartnerForm: React.FC<Props> = ({
  countries,
  createUniversitySuccessCallBack,
}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandler = (values: CreateUniversityForm) => {
    setIsLoading(true);
    console.log(values);
    const universityCode = miscUtils.generateUuid();

    createUniversity({ ...values, universityCode }).subscribe(
      (response) => {
        dispatch(
          notificationActions.setToast({
            severity: 'success',
            message: 'Successfully created Institute.',
            title: 'Success',
          })
        );
        createUniversitySuccessCallBack && createUniversitySuccessCallBack();
      },
      (error: AxiosError) => {
        dispatch(
          notificationActions.setToast({
            severity: 'danger',
            message: error.message,
            title: 'Error: Failed To Create Institute.',
          })
        );
        setIsLoading(false);
      }
    );
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      <Form>
        <div className="d-flex justify-content-center text-center" />

        <AppInput
          name="universityName"
          label="Name of Partner Institute"
          placeholder="Partner Institute"
          type="text"
        />
        <AppInput
          name="contactNo"
          label="Contact Number"
          placeholder="Contact Number"
          type="text"
        />
        <AppInput
          name="contactEmail"
          label="Email"
          placeholder="Email"
          type="email"
        />
        <AppInput
          name="contactInfo"
          label="Contact Info"
          placeholder="Contact Info"
          type="text"
        />
        <AppDropdown
          name="countryCode"
          label="Country"
          placeholder="Enter Country"
          loading={isLoading}
        >
          <option defaultChecked value="">
            --Please choose an option--
          </option>
          {countries &&
            countries.map((item) => (
              <option key={item.countryCode} value={item.countryCode}>
                {item.countryName}
              </option>
            ))}
        </AppDropdown>
        <AppButton type="submit" text="Add" />
      </Form>
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => ({
  countries: getCountryList(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AddPartnerForm);
