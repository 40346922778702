import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';

import {
  Mou,
  MouListState,
  MousRequest,
  MousResponse,
  MouState,
} from '../../../models/mou';
import { getSchema } from '../../../app/redux/schema';
import { emptyMeta, emptyState } from '../../../constants/api';
import { ApplicationType } from '../../../models/applicationType';
import { Coordinator } from '../../../models/coordinator';
import { Country, CountryState } from '../../../models/country';
import { Faculty } from '../../../models/faculty';
import { Stage, StageState } from '../../../models/stage';
import { University } from '../../../models/university';

export interface OrganizationReducerState {
  applicationType: ReturnType<typeof normalize> | null;
  coordinator: ReturnType<typeof normalize> | null;
  country: CountryState;
  stage: StageState;
  faculty: ReturnType<typeof normalize> | null;
  university: ReturnType<typeof normalize> | null;
  mou: MouListState;
}

export const initialState: OrganizationReducerState = {
  applicationType: null,
  coordinator: null,
  country: { list: null, loading: false, error: null },
  stage: { list: null, loading: false, error: null },
  faculty: null,
  university: null,
  mou: { ...emptyState, filters: undefined },
};

const fetchApplicationTypeListSuccess = (
  state: OrganizationReducerState,
  action: PayloadAction<Array<ApplicationType>>
): void => {
  state.applicationType = normalize(
    action.payload,
    getSchema('applicationTypeId')
  );
};

const fetchCoordinatorListSuccess = (
  state: OrganizationReducerState,
  action: PayloadAction<Array<Coordinator>>
): void => {
  state.coordinator = normalize(action.payload, getSchema('coordinatorId'));
};

const fetchCountryList = (state: OrganizationReducerState): void => {
  state.country = { list: null, loading: true, error: null };
};

const fetchCountryListSuccess = (
  state: OrganizationReducerState,
  action: PayloadAction<Array<Country>>
): void => {
  state.country = {
    list: normalize(action.payload, getSchema('countryCode')),
    loading: false,
    error: null,
  };
};

const fetchFacultyListSuccess = (
  state: OrganizationReducerState,
  action: PayloadAction<Array<Faculty>>
): void => {
  state.faculty = normalize(action.payload, getSchema('facultyId'));
};

const fetchStageList = (state: OrganizationReducerState): void => {
  state.stage = { list: null, loading: true, error: null };
};

const fetchStageListSuccess = (
  state: OrganizationReducerState,
  action: PayloadAction<Array<Stage>>
): void => {
  state.stage = {
    list: normalize(action.payload, getSchema('stageId')),
    loading: false,
    error: null,
  };
};

const fetchUniversityListSuccess = (
  state: OrganizationReducerState,
  action: PayloadAction<Array<University>>
): void => {
  state.university = normalize(action.payload, getSchema('universityCode'));
};

const fetchMouList = (
  state: OrganizationReducerState,
  action: PayloadAction<MousRequest>
): void => {
  state.mou = {
    items: null,
    meta: emptyMeta,
    loading: true,
    error: null,
    filters: {
      stageId: action.payload.stageId,
      countryCode: action.payload.countryCode,
      from: action.payload.from,
      to: action.payload.to,
    },
  };
};

const fetchMouListSuccess = (
  state: OrganizationReducerState,
  action: PayloadAction<MousResponse>
): void => {
  const mou = normalize(action.payload.items, getSchema('mouId'));
  state.mou = {
    ...state.mou,
    items: mou,
    meta: { ...action.payload.meta },
    loading: false,
    error: null,
  };
};

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    fetchApplicationTypeListSuccess,
    fetchCoordinatorListSuccess,
    fetchCountryList,
    fetchCountryListSuccess,
    fetchStageList,
    fetchStageListSuccess,
    fetchFacultyListSuccess,
    fetchUniversityListSuccess,
    fetchMouList,
    fetchMouListSuccess,
  },
});

const epicActions = {
  fetchApplicationTypeList: createAction('fetchApplicationType'),
  fetchCoordinatorList: createAction('fetchCoordinator'),
  fetchUniversityList: createAction('fetchUniversity'),
  fetchFacultyList: createAction('fetchFaculty'),
};

export const organizationActions = {
  ...organizationSlice.actions,
  ...epicActions,
};

export default organizationSlice.reducer;
